import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, InputGroup, FormControl, ProgressBar } from 'react-bootstrap';
import { getAccount } from "../hooks/useAccount";
import { addWhitelistMembers } from "../hooks/useAdmin";
import toast, { Toaster } from 'react-hot-toast';
import { IsValidAddress } from '../hooks/useContract';
import { addwhitelistvalidation } from '../hooks/kycvalidation';
import Papa from "papaparse";
import WAValidator from "wallet-address-validator"
import { isAddress } from 'web3-validator';
// import { useWeb3 } from "../hooks/useWeb3";
import Web3 from "web3"


class WhitelistModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

            whitelistModal: true,
            count: 1,
            inputList: [{ user: "", bnbvalue: "" }],
            isPending: false,
            errors: [],
            filename: "",
            fileuser: [],
            fileamount: [],
            allocateuser: "",
            users: [],
            amounts: [],
            values : [],
            csverrors:[]

        };
    }


    handleInputChange = async (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.inputList];
        list[index][name] = value;
        console.log(list, "sdf");
        if (name == "user" && value.length == 42) {
            const valid = await IsValidAddress(value);
            if (!valid) {
                toast.error(`Non Valid Address (${value}) !`,
                    {
                        style: {
                            minWidth: '700px',
                            minHeight: '55px'
                        }
                    });
            }
        }
    };


    handleRemoveClick = index => {
        console.log("index", index);
        const list = [...this.state.inputList];
        list.splice(index, 1);
        this.setState({ inputList: list });

    };

    handleAddUserList = async () => {
        this.setState({ isPending: true });
        this.setState({ errors: [] })
        // this.state.inputList.map(async(val , i)=>
        for (var i = 0; i < this.state.inputList.length; i++) {
            var val = this.state.inputList[i]
            let valid = await addwhitelistvalidation(val);
            console.log("valid", valid);
            if (!valid.isValid) {
                this.setState({ errors: [...this.state.errors, valid.errors] })
            }
        }
        if (this.state.errors.length == 0) {
            console.log("empty", this.state.errors.length,  this.props.saleAddress);
            await addWhitelistMembers(this.state.inputList,this.props.saleAddress,getAccount(),"add");
            this.setState({ isPending: false });
            this.props.onDismiss()
        }
        // console.log("valid " ,typeof(valid.errors),Object.keys(valid),Object.values(valid), valid.errors.length);
        // await addWhitelistMembers(this.st(ate.inputList,this.props.saleAddress,getAccount());
        // this.setState({ isPending : false });
        // this.props.onDismiss()
    }

    // handle click event of the Add button
    handleAddClick = () => {
        this.setState({ inputList: [...this.state.inputList, { user: "", bnbvalue: "" }] });
    };


    changeHandler = async (event) => {
        // const web3 = await useWeb3();
        let splitFile = event.target.files[0].name.split(".");
        console.log("splitFile", splitFile)
        if (splitFile[splitFile.length - 1] != "csv") {
            toast.error('Select your csv file !');
            return false
        }
        const valuesArray = [];
        this.setState({ 'filename': event.target.files[0].name })
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                console.log("result", results);

                let user = [];
                let amount = [];

                var userList = [];
                var bnbvalues = [];
                let InputList = []
                await results?.data?.map(async(m, i) => {
                    console.log("m", m, m.bnbvalues);
                    var keys = Object.values(m);
                    console.log("keys", keys);
                    let data = {
                        user: Object.values(m)[0],
                        bnbvalue: Object.values(m)[1]
                    }
                    InputList.push(data)
                    let valid = await addwhitelistvalidation(data);
                    console.log("validate",valid)
                    if (!valid.isValid) {
                        this.setState({ csverrors: "Invalid CSV file"})
                    }
                    else{
                        this.setState({ csverrors: ""})
                    }
                    // var validate =Web3.utils.isAddress(Object.values(m)[0]); 
                    userList.push(Object.values(m)[0])
                    // var val =Web3.utils.isAddress("0x42C30f082eb3a6b0eDd501892fCdc51d8FB69c68")
                    bnbvalues.push(Object.values(m)[1])
                    // console.log("validate",validate,val)
                })
             
                console.log("user", userList, bnbvalues);
                // this.setState({fileuser : JSON.parse(localStorage.getItem("user")) , fileamount : JSON.parse(localStorage.getItem("amount")) , allocateuser : JSON.parse(localStorage.getItem("data")),
                //     users : JSON.parse(localStorage.getItem("user")) , amounts : JSON.parse(localStorage.getItem("amount"))})
                this.setState({
                    fileuser: userList, fileamount: bnbvalues, allocateuser: JSON.stringify(results?.data),
                    users: userList, amounts: bnbvalues, inputList: InputList
                })
                this.setState({ values: results?.data })
                console.log("values",this.state.values)
            },
        });
    }

    render() {


        const { whitelistModal } = this.state


        return (


            <Modal className="wallet-modal" show={whitelistModal} centered size="md">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h3 className="sec-head ">Add Whitelist Users</h3>
                    <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body">

                    {[...Array(this.state.count)].map((val, i) => {
                        return (
                            <div>
                                 <p className='text-white'> Notes* : Enter details by manually or by uploading csv file. </p>
                                <div className="row form_row row_add_remove mb-2 px-3">
                                    <div className="col-6 col-sm-5 px-1">

                                        <div className="inputs input-groups">
                                            <InputGroup className="">
                                                <FormControl name="user" placeholder="Wallet Address"
                                                    onChange={e => this.handleInputChange(e, i)}
                                                />

                                            </InputGroup>
                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors[i]?.user}</span>
                                        </div>

                                    </div>
                                    <div className="col-6 col-sm-5  px-1">

                                        <div className="inputs input-groups">
                                            <InputGroup className="">
                                                <FormControl name="bnbvalue" placeholder={`Allocated ${this.props.buyToken} Value`}
                                                    onChange={e => this.handleInputChange(e, i)}
                                                />

                                            </InputGroup>
                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors[i]?.bnbvalue}</span>
                                        </div>


                                    </div>
                                    {this.state.count > 1 &&
                                        <div className="col-2 col-sm-1  px-1">
                                            <button
                                                className="get-started-btn bnt_icon_new mb-2 mt-sm-2 mt-3" onClick={() => {
                                                    this.setState({ count: this.state.count - 1 })
                                                    this.handleRemoveClick(i);
                                                }}><i className="fa fa-trash-alt"></i></button>
                                        </div>}






                                    {/* 
                        <label className="custom-file-label mb-0" for="inputGroupFile04">
                     {this?.state?.filename ? this?.state?.filename : "Choose CSV file"}</label> */}

                                    {this.state.count >= 1 &&
                                        <div className="col-2 col-sm-1 px-1">
                                            <button className="get-started-btn bnt_icon_new mb-2 mt-sm-2 mt-3 ml-1" onClick={() => {
                                                this.setState({ count: this.state.count + 1 });
                                                this.handleAddClick();
                                            }}><i className="fa fa-user-plus"></i></button>
                                        </div>
                                    }



                                </div>

                            </div>
                        );
                    })}



                    <div className='col-12 px-1 mt-3'>

                        <div className="inputs input-groups w-100">
                            <InputGroup className="file_grp_input">
                                <div className="w-100">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"
                                            onChange={async (e) => {
                                                var result = this.changeHandler(e);
                                                // let user = [];
                                                // let amount = [];
                                                // await result?.dataParse?.map((m , i) => {
                                                //     user.push(m.address);
                                                //     amount.push(m.amount);
                                                // })
                                                //  this.setState({fileuser : result?.user , fileamount : result?.amount , filename : e?.target?.files[0]?.name , allocateuser : result?.ref,
                                                //   users : result?.user , amounts : result?.amount});
                                                this.setState({ filename: e?.target?.files[0]?.name })
                                                // console.log("result" , result);

                                            }}
                                        />
                                        <label className="custom-file-label mb-0" for="inputGroupFile04">
                                            {this?.state?.filename ? this?.state?.filename : "Choose CSV file"}</label>
                                    </div>
                                    <span className="text-danger f-12 d-block text-left">{this?.state?.csverrors}</span>

                                </div>

                            </InputGroup>
                            <span className="text-danger"></span>
                        </div>

                    </div>

                    {console.log("this.props", this.props)}
                    <div className='col-12 col-md-12 mb-3 mt-3 text-right'>

                        <a href="javascript:void(0)" className='csv_modal_green_text' onClick={() => { this.props.modalShowHide() }}>Sample CSV file</a>


                    </div>


                    <div className="text-center">
                        <button className="get-started-btn mt-4" onClick={this.handleAddUserList}>Submit</button>

                    </div>

                </Modal.Body>
            </Modal>



        )
    }

}


export default WhitelistModal