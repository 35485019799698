
export const weekcalculation =(data)=>{
    try{
        console.log(data,"data")
        let Startminus = data * 7 
        let currentDate =new Date(new Date().setDate(new Date().getDate() - Startminus))
        console.log(currentDate,"currentDate")
        var year = new Date(currentDate.getFullYear(), 0, 1)
        var days = Math.floor((currentDate - year) / (24 * 60 *60 * 1000));
        var week = Math.ceil(days / 7)
        var yr =currentDate.getFullYear()
        let val = week +"/"+ yr
        return val
    }catch(err){
        console.log(err,'weekcalculation_err')
    }
}


