import React, { Component } from "react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../Header";
import Sidebar from "../Sidebar";
import PlaceholdingLoader from "../PlaceholdingLoader";
import Trendingslider from '../trendingslider';
import {GetTokenInfo} from '../../hooks/useContract'

import eth from "../../images/eth.png";
import DAI from "../../images/DAI.png";
import USDC from "../../images/USDC.png";
import USDT from "../../images/USDT.png";

import { weekcalculation } from '../../helper/weekCal'
import "../../css/styles.css";

import home_image from "../../images/home_image.svg";
import home_logo from "../../images/home_logo.svg";

import branding_icon from "../../images/branding.png";
import community_icon from "../../images/community.png";
import customization_icon from "../../images/Customization.png";
import deflationary_icon from "../../images/Deflationary.png";
import launchpad_icon from "../../images/Launchpad.png";
import locking_icon from "../../images/locking.png";
import management_icon from "../../images/Management.png";
import standard_icon from "../../images/standard.png";
import crypto_moon from "../../images/cryptomoon.png";
import swap_icon from "../../images/swap.png";
import elon_icon from "../../images/elon.png";
import coin_img from "../../images/Bitdeal/dump.png";

import bgstyle2 from "../../images/bg_style2.png";

import bgstyle from "../../images/bg_style.png";

import bgoutline1 from "../../images/bg_outline1.png";
import whiteoutline1 from "../../images/outline-white1.png";

import bgoutline from "../../images/bg_outline.png";
import whiteoutline from "../../images/outline-white.png";
import bgoutline2 from "../../images/bg_outline2.png";
import whiteoutline2 from "../../images/outline-white2.png";


import favicon from "../../images/Bitdeal/logos.png";

import char_robot from "../../images/char_robot.png";


import vector_green1 from "../../images/vector_green1.png";
import vector_green2 from "../../images/vector_green2.png";
import vector_green3 from "../../images/vector_green3.png";


import vector_small1 from "../../images/vector_small1.png";
import vector_small2 from "../../images/vector_small2.png";
import vector_small3 from "../../images/vector_small3.png";

import token_image from "../../images/token_img.png";

import partner1 from "../../images/partner1.png";
import partner2 from "../../images/partner2.png";
import partner3 from "../../images/partner3.png";
import partner4 from "../../images/partner4.png";
import partner5 from "../../images/partner5.png";
import partner6 from "../../images/partner6.png";
import partner7 from "../../images/partner7.png";









import pool_flower from "../../images/pool_flower.png";

import hot_contest from "../../images/hot_contest.png"




import bgoutlinedarkmid from "../../images/outline_dark_middile.png";
import bgoutlinelightmid from "../../images/outline_light_middile.png";








import {
  Container,
  Row,
  Col,
  Card,
  ProgressBar,
  InputGroup,
  Form,
  Tab,
  Nav
} from "react-bootstrap";
import { getallsalehook, gethomecalculationhook, gettrendingdisplayhook } from "../../hooks/usebackend";
import { gethomelaunchpaddetail, getTotalSalesInfo, isSaleLive, isUpcoming,getlaunchpaddatalimit } from "../../hooks/useProjects";
import { Userdollar,getAccount } from "../../hooks/useAccount";
import { getSaleInfoCard, Totalvaluelocked, UseTokenInfo } from "../../hooks/useContract";



class Landing extends Component {
 
  




  constructor(props) {
    super(props);
    this.state = {
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      setUpdateCms: false,
      trending : [],
      projects : 0,
      bnbindollar : 0,
      users : 0,
      loadingText:false,
      totallockedvalue : 0,


      auditkyc : [],
      singleaudit : {},
      ProxyCount :0,



      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      mycontribution: [],
      accountInfo: getAccount(),
      interval: 0,
      loadinterval: 3,
      original: [],
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      trending: [],
      dummylaunchpad: [],
      trending: [],
      filterSales: [],
      viewMore: true,
      arrayofWeek: 20,
      filteredSales:[],
      sumofEarned:0,
      successSale:0,
      participants:0,
      tokeninfo:[],
      trendingSale:[]

    };
  }

  componentDidMount(){
    document.getElementById("home_grad_aprent").classList.add("active");
    this.handlegetkyc()
    this.gettrending();
    this.loadData();
    this.Proxydata();
    this.tokenInfo();
    // this.trending();
  }


  handlegetkyc = async() => {
    let auditkycinfo = await getallsalehook();
      if(auditkycinfo?.data?.data?.length > 0){
          this.setState({auditkyc : auditkycinfo?.data?.data})
      }

      let singleaudit = auditkycinfo?.data?.data?.find(e => e.saleaddress == window.location.pathname.split('/')[2])
      console.log("auditkuycinfo" , auditkycinfo?.data?.data , singleaudit);

      if(singleaudit)
      this.setState({singleaudit : singleaudit})
  }

  async gettrending(){
    let data = await gettrendingdisplayhook();
    console.log("dataa trending" , data?.data?.data);
    if(data?.data?.data?.length > 0){
      let trenddata = await gethomelaunchpaddetail(data?.data?.data)
      console.log("trenddata" , trenddata);
      this.setState({trending : trenddata}) 
      const Proxydata = await getTotalSalesInfo();
     
      if(Proxydata?.length > 0){
        let totallock = 0;
        this.setState({projects : Proxydata?.length});
        Proxydata?.map(async(data)=>{console.log("mapdata" , data);
          let saledata = await getSaleInfoCard(data?._sale);
          
          let value = saledata.presaleRate * saledata.hardCap/10**18;
          
          console.log("calculated value" , value , totallock);
          totallock = totallock + value;
          this.setState({totallockedvalue : Math.round(totallock*100)/100})
        })
        
      }
      
      

      let calculation = await gethomecalculationhook();
      console.log("calculation" , calculation);
      let dollar = await Userdollar(calculation?.data?.totalbnb);
      console.log("dollar in bnb" , Math.round(dollar*100)/100);
      this.setState({bnbindollar : Math.round(dollar*100)/100})
      this.setState({users : calculation?.data?.user});
      // let lockedvalue = await Totalvaluelocked();
      // this.setState({loadingText : false})
    }
  }


  async loadData() {
    // this.showLoader();
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    // console.log("Proxydata", Proxydata.length);
    // this.setState({ProxyCount : Proxydata.length})
    // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == false)
    let trenddata = await gettrendingdisplayhook();
    console.log("dataa trending", trenddata?.data?.data);
    if (trenddata?.data?.data?.length > 0)
      this.setState({ trending: trenddata?.data?.data })
    var trending = trenddata?.data?.data
    console.log("trendingdataaaaaaaaaaaaaa", trending);
    let newtotalproxy = [];
    trending?.map((val, i) => {
      let singledata = Proxydata.find(e => e?._sale == val.saleaddress);
      if (singledata) {
        newtotalproxy.push(singledata)
      }
    })
    //add on works
    // let dummylaunchpad = await getusecreatedlaunchhook();
    // var dummylaunch = [];
    // console.log("get dummy launchpad" , dummylaunchpad);
    // if(dummylaunchpad?.data?.type == "success"){
    //   dummylaunch = dummylaunchpad?.data?.data?.filter(data => data?.whitelist != "privatesale");
    //   this.setState({dummylaunchpad : dummylaunch})
    //   newtotalproxy = dummylaunch ? newtotalproxy.concat(dummylaunch) : []
    // }



    Proxydata?.map((datas, i) => {
      let singlesale = newtotalproxy.find(e => e._sale == datas?._sale);
      if (!singlesale) {
        newtotalproxy.push(datas)
      }
    })
    this.setState({ proxy: newtotalproxy })
    console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
    console.log("total proxy data", Proxydata);
    // this.setState({ proxy : totalproxydata })
    this.setState({ original: newtotalproxy })
    const total = await getlaunchpaddatalimit(newtotalproxy, 0, newtotalproxy.length);
    console.log("total>>>", total);
    // const total = await getPrivatesaleCardLimit(Proxydata,0,this.state.interval , this.state.isuser);
    // console.log("button " ,Proxydata?.length , total.index );
    // if(Proxydata?.length == total.index || !total){
    //   this.setState({button : false})
    // }
    // await this.sleep(1000)
    //  this.setState({ totalSales: total.saleInfoCards });  
    //  this.setState({ interval: total.index+1 }); 
    this.setState({ totalSales: total });
    var trending =[]
    this.state.trending?.map((val, i) => {
      console.log("trenddataval",val)
      let singledata = total.find(e =>{
        console.log("e?._sale == val.saleaddress",e?.saleaddress == val.saleaddress)
       return e?.saleaddress === val.saleaddress
      });
      console.log("singledata",singledata)
      if (singledata) {
        trending.push(singledata)
      }

    })
    this.setState({trendingSale:trending})
    let sumofEarned = total?.reduce((total, currentValue) => {
      return total = parseFloat(total) + parseFloat(currentValue.earnedCap);

    }, 0)
    this.setState({sumofEarned :(sumofEarned)/10**18})
    
    let participants = total?.reduce((total, currentValue) => {
      return total = parseFloat(total) + parseFloat(currentValue.participants);

    }, 0)
    this.setState({participants :participants})

    this.setState({ interval: this.state.loadinterval });
    // this.hideLoader();
    // this.filterdata(weekcalculation(0),0)
  }

  async loadMore() {
    console.log("Lad moreee", this.state.totalSales?.length < this.state.proxy?.length)
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(this.state.proxy, this.state.interval, this.state.interval + this.state.loadInterval);
    console.log("newly ", newly);
    // if(newly.length === 0){
    //   this.setState({"viewMore":false})
    // }
    const total = this.state.totalSales?.concat(newly);
    this.setState({ totalSales: total, interval: this.state.interval + this.state.loadInterval });
    this.setState({ isLoading: false });
  }

  async Proxydata() {
   try{ console.log("Proxydata");
 
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata?.length);
    this.setState({ProxyCount : Proxydata?.length})
  }
  catch(err){
    console.log("err",err)
  }
    // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == false)
  }

// async filterdata(data,i) {
//     console.log("data",data,i,this.state.totalSales)
//     let filterdata = [];
//     let Sales = this.state.totalSales.filter(values => {
//       let currentDate = new Date(parseFloat(values.startTime) * 1000)
//       console.log(currentDate, "currentDate")
//       var year = new Date(currentDate.getFullYear(), 0, 1)
//       var days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
//       var week = Math.ceil(days / 7);
//       let val = week + "/" + currentDate.getFullYear()
//       console.log("val == data",val == data,val,data)
//       return val == data
//     })
//     Sales.sort(function(a,b){
//        console.log(parseFloat(a.earnedCap) > parseFloat(b.earnedCap),"sort")
//        return parseFloat(a.earnedCap) > parseFloat(b.earnedCap)
//     });

//     let sumofEarned = Sales.reduce((total, currentValue) => {
//       return total = parseFloat(total) + parseFloat(currentValue.earnedCap);

//     }, 0)
//     this.setState({sumofEarned :(sumofEarned)/10**18})
    
//     let successSale = Sales.filter(values =>{
//       console.log("values.softCap > values.earnedCap",values.softCap > values.earnedCap)
//      return values.earnedCap> values.softCap 
      
//     })
//     this.setState({successSale :successSale.length})

//     console.log("Sales",Sales,successSale,)
//     // TradeHoursData.sort(function (a, b) {
//     //   console.log(parseFloat(a.day) - parseFloat(b.day))
//     //          return parseFloat(a.day) - parseFloat(b.day);
//     //   });
//     this.setState({filteredSales : Sales})
//     console.log("Sales_filterdata", Sales,this.state.filteredSales)
//   }


  async tokenInfo() {
    const token = await GetTokenInfo();
    console.log("tokentokentoken", token);
    this.setState({tokeninfo:token})
  }

  // async trending(){
  //   try{
  //   console.log("trendingsale")
  //   // const trendingsale = await getTotalSalesInfo();
  //   // console.log("trendingsale", trendingsale);
  //   const trendingsale = await getlaunchpaddatalimit();
  //   console.log("trendingsale", trendingsale);
  //   let trenddata = await gettrendingdisplayhook();
  //   console.log("gettrendingdisplayhook", trenddata?.data?.data);
  //   let newtotalproxy = [];
  //   trenddata?.data?.data?.map((val, i) => {
  //     console.log("trenddataval",val)
  //     let singledata = trendingsale.find(e =>{
  //       console.log("e?._sale == val.saleaddress",e?._sale == val.saleaddress)
  //      return e?._sale == val.saleaddress
  //     });
  //     console.log("singledata",singledata)
  //     if (singledata) {
  //       newtotalproxy.push(singledata)
  //     }

  //   })
  //   this.setState({trendingSale:newtotalproxy})
  // }catch(err){
  //   console.log("err",err)
  // }
  // }

  render() {
 

    return (
      <div id="loader_main">
      
        <div className="logo_overlay" id="logo_overlay" style={{opacity:1}}>
          <Header />

          <div className="whole_sec pb-5">
            <div className="flex_side_right">
              <Sidebar />
              {/* ongoing_sec */}
              <div className="right_side_sec">
                {/* <div className="text-white topBar d-flex justify-content-between">
                  
                    <small>Trending</small>
                  <small>
                    #1&nbsp;<span>Husy</span>
                  </small>
                  <small>
                    #2&nbsp;<span>ORL</span>
                  </small>
                  <small>
                    #3&nbsp;<span>UWC</span>
                  </small>
                  <small>
                    #4&nbsp;<span>777</span>
                  </small>
                  <small>
                    #5&nbsp;<span>IDXS</span>
                  </small>
                  <small>
                    #6&nbsp;<span>COUGNU</span>
                  </small>
                  <small>
                    #7&nbsp;<span>CRICLE</span>
                  </small>
                  <small>
                    #8&nbsp;<span>KASA</span>
                  </small>
                  <small>
                    #9&nbsp;<span>FIFAPP</span>
                  </small>
                  <small>
                    #10&nbsp;<span>SOG</span>
                  </small>
                  <small>
                    #11&nbsp;<span>COOSHA</span>
                  </small>
                  <small>
                    #12&nbsp;<span>Honey</span>
                  </small>
                  
                  
                </div> */}
                <Trendingslider/>
<div className="right_side_spacing pb-5">
                <Container className="text-white container mt-5 pb-4 take_section">

                <div class="liquid_raised">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-9 col-xl-9">
                    <h3 className="h3_res">Innovating the EVM ecosystem through the AIO DeFi Launchpad</h3>
                    <p className="title_span">
                    By virtue of its efficient and user-friendly interface, KittySwap enables projects to create tokens and presales in a matter of seconds.
                    </p>
                    <button className="get-started-btn" onClick={()=>this.props.history.push('/create')}>Create</button>

                    </div>
                    <div className="col-12 col-md-2 col-lg-3 col-xl-3">
                      <img src={char_robot} className="img-fluid ing_char_robot" />
                      </div>
                  </div>
                </div>
                 
                  
                  <center>
                   

                   
                    <br />
                   
                  </center>
                  {/* <div className="mt-4 d-flex justify-content-center btn_sec_mob_res">
                    <a href = "https://docs.cryptolaunchpad.finance/" target="_blank"><button className="get-started-btn">Learn more</button></a>
                    <a href = "https://github.com/freshcoins/Smart-Contract-Audits/blob/main/SpaceAI_0x358E5D2378f5Fc3fA5504aEbb728c4C568F973a4.pdf" target="_blank"><button className="get-started-btn ml-3">Audit</button></a>
                  </div> */}
                </Container>

                <Container>
                  <div className="row">
                    <div className="col-12 col-md-11 col-xl-10 mx-auto">
                      <div className="row">
                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <div className="flex_text_div_home">
                          <div class="card_sec_image p-3">
                            <img src={vector_green1} className="img-fluid" />
                          </div>
                          <div className="ml-3">
                            <p className="mb-1 card_img_head">Raised contributions</p>
                            <p className="mb-0 card_img_subhead">{this.state.sumofEarned}</p>
                          </div>
                          </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <div className="flex_text_div_home">
                          <div class="card_sec_image p-3">
                            <img src={vector_green2} className="img-fluid" />
                          </div>
                          <div className="ml-3">
                            <p className="mb-1 card_img_head">Total projects</p>
                            <p className="mb-0 card_img_subhead">{this.state.ProxyCount}</p>
                          </div>
                          </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <div className="flex_text_div_home">
                          <div class="card_sec_image p-3">
                            <img src={vector_green3} className="img-fluid mt-1" />
                          </div>
                          <div className="ml-3">
                            <p className="mb-1 card_img_head">Unique participants</p>
                            <p className="mb-0 card_img_subhead">{this.state.participants}</p>
                          </div>
                          </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </Container>

                <Container>
                  <div className="row mt-3">
                    <div className="col-12 col-md-12 col-xl-4 mb-3">
                      <div className="card card_home_style_2 card_bg_robot card_bg_robot_sal pb-4">
                        <div className="heading_card mb-2">
                          <img src={vector_small1} className="img-fluid" />
                          <p className="mb-0 pl-3">Latest Pools</p>
                        </div>
                        {console.log("this.state.totalSales",this.state.totalSales,this.state.totalSales?.length - 1)}
                       {this.state.totalSales?.length > 0 ? this.state.totalSales?.map((val, i) => {
                        if(i === 0 || i ===  2  || i === 3 ){
                         return (
                        <div className="heading_pools mt-3">
                          <div className="headeing_tet_flex">
                            <img src={val?.logo} className="img-fluid" />
                            <div className="ml-3">
                              <p className="mb-0 heading_pool_head">{val?.name}</p>
                              <p className="mb-0 heading_pool_subhead">{val?.symbol}</p>
                            </div>
                          </div>
                          <badge className="badge_green_token">{val?.coin}</badge>
                        </div>
                         ) }
                       }) : <p className='text-center mt-3 nodata_tet'>No data found</p>
                      }
                        {/* <div className="heading_pools mt-3">
                          <div className="headeing_tet_flex">
                            <img src={pool_flower} className="img-fluid" />
                            <div className="ml-3">
                              <p className="mb-0 heading_pool_head">Waifu</p>
                              <p className="mb-0 heading_pool_subhead">Waifu</p>
                            </div>
                          </div>
                          <badge className="badge_green_token">ETHW</badge>
                        </div>
                        <div className="heading_pools mt-3">
                          <div className="headeing_tet_flex">
                            <img src={pool_flower} className="img-fluid" />
                            <div className="ml-3">
                              <p className="mb-0 heading_pool_head">Waifu</p>
                              <p className="mb-0 heading_pool_subhead">Waifu</p>
                            </div>
                          </div>
                          <badge className="badge_green_token">ETHW</badge>
                        </div> */}
                      </div>
                    </div>

                    <div className="col-12 col-md-12 col-xl-4 mb-3">
                      <div className="card card_home_style_2 pb-4">
                        <div className="heading_card mb-2">
                          <img src={vector_small2} className="img-fluid" />
                          <p className="mb-0 pl-3">Hot Contests</p>
                        </div>
                        {this.state.trendingSale.length > 0 ? this.state.trendingSale?.map((val, i) => {
                          return(
                        <div className="heading_pools mt-3">
                          <div className="headeing_tet_flex">
                            <img src={val.logo} className="img-fluid" />
                            <div className="ml-3">
                              <p className="mb-0 heading_pool_head">{val?.name}</p>
                            </div>
                          </div>
                          <badge className="badge_green_token">{val?.coin}</badge>
                        </div>
                          )
                   
                        }): <p className='text-center mt-3 nodata_tet'>No data found</p>
                        }
                      </div>
                    </div>

                    <div className="col-12 col-md-12 col-xl-4 mb-3">
                      <div className="card card_home_style_2 pb-4">
                        <div className="heading_card mb-2">
                          <img src={vector_small3} className="img-fluid" />
                          <p className="mb-0 pl-3">New Tokens</p>
                        </div>
                        {console.log("tokeninfo",this.state.tokeninfo)}
                        {this.state.tokeninfo?.length > 0 ? this.state.tokeninfo?.map((val, i) => {
                          return(
                        <div className="heading_pools mt-3">
                          <div className="headeing_tet_flex">
                            {/* <div className="token_img_bg"> */}
                            {/* <img src={token_image} className="img-fluid" /> */}

                            {/* </div> */}
                            <div className="ml-3">
                              <p className="mb-0 heading_pool_head">{val._name}</p>
                              <p className="mb-0 heading_pool_subhead">{val._symbol}</p>
                            </div>
                          </div>
                          {/* <badge className="badge_green_token">ETHW</badge> */}
                        </div>
                          )  
                          }) : <p className='text-center mt-3 nodata_tet'>No data found</p>
                         }
                        {/* <div className="heading_pools mt-3">
                          <div className="headeing_tet_flex">
                          <div className="token_img_bg">
                            <img src={token_image} className="img-fluid" />

                            </div>
                            <div className="ml-3">
                              <p className="mb-0 heading_pool_head">Neko</p>
                              <p className="mb-0 heading_pool_subhead">NEKO</p>
                            </div>
                          </div>
                          <badge className="badge_green_token">ETHW</badge>
                        </div>
                        <div className="heading_pools mt-3">
                          <div className="headeing_tet_flex">
                          <div className="token_img_bg">
                            <img src={token_image} className="img-fluid" />

                            </div>
                            <div className="ml-3">
                              <p className="mb-0 heading_pool_head">Vision</p>
                              <p className="mb-0 heading_pool_subhead">VIS</p>
                            </div>
                          </div>
                          <badge className="badge_green_token">ETHW</badge>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </Container>
{/* 
                <Container>
                <div className="card card_home_style_2 py-0 mt-3">
                  <div className="img_flex">
                  <p className="text_green_partners mb-0">Partners</p>
                  <div className="img_content_scroll">
                  <img src={partner1} className="img-fluid" />
                  <img src={partner2} className="img-fluid" />
                  <img src={partner3} className="img-fluid" />
                  <img src={partner4} className="img-fluid" />
                  <img src={partner5} className="img-fluid" />
                  <img src={partner6} className="img-fluid" />
                  <img src={partner7} className="img-fluid" />

                  </div>
                  </div>
                  <div>

                  </div>
                </div>
                </Container> */}

                <Container className="mt-4">
                   <center>
                    <small className="bottom_text">
                      Disclaimer: The information provided shall not in any way
                      constitute a recomendation as to whether you should invest
                      in any product discussed. We accept no liability for any
                      loss occasioned to any person acting or refraining from
                      action as a result of any material provided or published.
                    </small>
                  </center>
                </Container>

                {/* <Container className="mt-0 container pt-4 pb-4 conta_toppols contain_iomg_mid">
               

              
                  <div className={this.state.trending ? "row justify-content-center" : "d-none"}>
                  <div className={this.state.trending[0]?'col-12 col-xl-4 col-lg-6 col-md-6 col-lg-6-custom mb-4 projects' : "d-none"}
                    onClick = {()=>{ console.log("whitelist" , this.state.trending[1]?.isWhitelisted , this.state.trending);
                      if(this.state.trending[0]?.isWhitelisted)
                        window.location.href = window.origin + `/privatesaledetail/${this?.state?.trending[0]?.saleAddress}`
                      else
                        window.location.href = window.origin + `/launchpaddetail/${this?.state?.trending[0]?.saleAddress}`
                      }
                    }
                  >
                  
                  <div className='card card_style_1 ribbox'>
                     
                      <div className='card-body'>
                      <span class="badge badge-kyc-rect mt-0 mb-2 badge_trend"><span class="blk_txt">#OnTop1</span></span>
                          <div className='pt-5 px-3 pb-3'>
                          <div className='grid_img_div'>
                              <div className='profimg'>
                                 
                                 <img src={this?.state?.trending[0]?.logo} alt="prof" />
                             </div>
                             <div>
                              <div className="btn-group btn_grp_yel mb-2 d-block d-xl-flex d-md-block d-sm-flex flex_cont_end_flex" role="group" aria-label="Basic example">
                                 
                                  <div className='d-sm-flex d-block mt-3 mt-xl-0 mt-md-3 mt-sm-0 text-right-xss'>
                                  <p className=' mb-0'>
                               
                                          
                                          <span className="badge badge_live">                      
                                         
                                          <span className='stats_txt'>{
                                              this?.state?.trending && isSaleLive(this?.state?.trending[0]?.startTime,this?.state?.trending[1]?.endTime,this?.state?.trending[0]?.isPresaleOpen) ?
                                             " Sales Live"
                                            : this?.state?.trending[0] && isUpcoming(this?.state?.trending[0].startTime)? "Upcoming" : "Sale Ended"
                                            }</span>
                                          </span>
                                 
                                
                                  </p>
                          </div>
                              </div>
                              
                             
                              {this.state.auditkyc.find(e => e.saleaddress == this?.state?.trending[0]?.saleAddress) &&<p className='text-right'>
                              <span className="badge badge-green-rect mt-0 mr-2">
                                  <span className='blk_txt'><a className="text_in_badge_a" href={this.state.auditkyc.find(e=> e.saleaddress == this?.state?.trending[0]?.saleAddress)?.audit} target = "_blank">Audit</a></span>
                                  </span>
                                  <span className="badge badge-kyc-rect mt-0">
                                  <span className='blk_txt'><a className="text_in_badge_a" href={this.state.auditkyc.find(e=> e.saleaddress == this?.state?.trending[0]?.saleAddress)?.audit} target = "_blank">KYC</a></span>
                                  </span>
                              <span className="badge badge-yellow-fill mt-0">
                                 
                                  </span> 
                               </p>}
                          </div>
           
                     
                          </div>
                
                         
                       
                          <p className='text-white cardhead font_16 mb-1 mt-3'>{this?.state?.trending[0]?.name}</p>
                          <p className="desc_grey_txt pb-0 mb-0">1 BNB = {this?.state?.trending[0]?.presaleRate+" " + this?.state?.trending[0]?.symbol}</p>
                         
          
                              
                   
                    
                     
             
             
                  
                      
                          </div>
                      </div>
                  </div>
                  
          
              </div>

              <div className={this.state.trending[1]?'col-12 col-xl-4 col-lg-6 col-md-6 col-lg-6-custom mb-4 projects' : "d-none"}
                 onClick = {()=>{ console.log("whitelist" , this.state.trending[1]?.isWhitelisted);
                  if(this.state.trending[1]?.isWhitelisted)
                    window.location.href = window.origin + `/privatesaledetail/${this?.state?.trending[1]?.saleAddress}`
                  else
                    window.location.href = window.origin + `/launchpaddetail/${this?.state?.trending[1]?.saleAddress}`}
                }
              >
                  
                  <div className='card card_style_1 ribbox'>
                     
                      <div className='card-body'>
                      <span class="badge badge-kyc-rect mt-0 mb-2 badge_trend"><span class="blk_txt">#OnTop2</span></span>
                          <div className='pt-5 px-3 pb-3'>
                          <div className='grid_img_div'>
                              <div className='profimg'>
                                 
                                 <img src={this?.state?.trending[1]?.logo} alt="prof" />
                             </div>
                             <div>
                              <div className="btn-group btn_grp_yel mb-2 d-block d-xl-flex d-md-block d-sm-flex flex_cont_end_flex" role="group" aria-label="Basic example">
                                 
                                  <div className='d-sm-flex d-block mt-3 mt-xl-0 mt-md-3 mt-sm-0 text-right-xss'>
                                  <p className=' mb-0'>
                               
                                          
                                          <span className="badge badge_live">                      
                                         
                                              <span className='stats_txt'>{
                                              this?.state?.trending && isSaleLive(this?.state?.trending[1]?.startTime,this?.state?.trending[1]?.endTime,this?.state?.trending[1]?.isPresaleOpen) ?
                                             " Sales Live"
                                            : this?.state?.trending[1] && isUpcoming(this?.state?.trending[1].startTime)? "Upcoming" : "Sale Ended"
                                            }</span>
                                          </span>
                                 
                                
                                  </p>
                          </div>
                              </div>
                              
                             
                              {this.state.auditkyc.find(e => e.saleaddress == this?.state?.trending[1]?.saleAddress) &&<p className='text-right'>
                              <span className="badge badge-green-rect mt-0 mr-2">
                                  <span className='blk_txt'><a className="text_in_badge_a" href={this.state.auditkyc.find(e=> e.saleaddress == this?.state?.trending[1]?.saleAddress)?.audit} target = "_blank">Audit</a></span>
                                  </span>
                                  <span className="badge badge-kyc-rect mt-0">
                                  <span className='blk_txt'><a className="text_in_badge_a" href={this.state.auditkyc.find(e=> e.saleaddress == this?.state?.trending[1]?.saleAddress)?.audit} target = "_blank">KYC</a></span>
                                  </span>
                              <span className="badge badge-yellow-fill mt-0">
                                 
                                  </span> 
                               </p>}
                          </div>
           
                     
                          </div>
                
                         
                       
                          <p className='text-white cardhead font_16 mb-1 mt-3'>{this?.state?.trending[1]?.name}</p>
                          <p className="desc_grey_txt pb-0 mb-0">1 BNB = {this?.state?.trending[1]?.presaleRate + " " +this?.state?.trending[1]?.symbol}</p>
                         
          
                              
                   
                    
                     
             
             
                  
                      
                          </div>
                      </div>
                  </div>
                  
          
              </div>

              <div className={this.state.trending[2]?'col-12 col-xl-4 col-lg-6 col-md-6 col-lg-6-custom mb-4 projects' : "d-none"}
                 onClick = {()=>{ console.log("whitelist" , this.state.trending[2]?.isWhitelisted);
                  if(this.state.trending[2]?.isWhitelisted)
                    window.location.href = window.origin + `/privatesaledetail/${this?.state?.trending[2]?.saleAddress}`
                  else
                    window.location.href = window.origin + `/launchpaddetail/${this?.state?.trending[2]?.saleAddress}`}
                }
              >
                  
                  <div className='card card_style_1 ribbox'>
                     
                      <div className='card-body'>
                      <span class="badge badge-kyc-rect mt-0 mb-2 badge_trend"><span class="blk_txt">#OnTop3</span></span>
                          <div className='pt-5 px-3 pb-3'>
                          <div className='grid_img_div'>
                              <div className='profimg'>
                                 
                                 <img src={this?.state?.trending[2]?.logo} alt="prof" />
                             </div>
                             <div>
                              <div className="btn-group btn_grp_yel mb-2 d-block d-xl-flex d-md-block d-sm-flex flex_cont_end_flex" role="group" aria-label="Basic example">
                                 
                                  <div className='d-sm-flex d-block mt-3 mt-xl-0 mt-md-3 mt-sm-0 text-right-xss'>
                                  <p className=' mb-0'>
                               
                                          
                                          <span className="badge badge_live">                      
                                         
                                          <span className='stats_txt'>{
                                              this?.state?.trending && isSaleLive(this?.state?.trending[3]?.startTime,this?.state?.trending[3]?.endTime,this?.state?.trending[3]?.isPresaleOpen) ?
                                             " Sales Live"
                                            : this?.state?.trending[3] && isUpcoming(this?.state?.trending[3].startTime)? "Upcoming" : "Sale Ended"
                                            }</span>
                                          </span>
                                 
                                
                                  </p>
                          </div>
                              </div>
                              
                             
                              {this.state.auditkyc.find(e => e.saleaddress == this?.state?.trending[2]?.saleAddress) &&<p className='text-right'>
                              <span className="badge badge-green-rect mt-0 mr-2">
                                  <span className='blk_txt'><a className="text_in_badge_a" href={this.state.auditkyc.find(e=> e.saleaddress == this?.state?.trending[2]?.saleAddress)?.audit} target = "_blank">Audit</a></span>
                                  </span>
                                  <span className="badge badge-kyc-rect mt-0">
                                  <span className='blk_txt'><a className="text_in_badge_a" href={this.state.auditkyc.find(e=> e.saleaddress == this?.state?.trending[2]?.saleAddress)?.audit} target = "_blank">KYC</a></span>
                                  </span>
                              <span className="badge badge-yellow-fill mt-0">
                                 
                                  </span> 
                               </p>}
                          </div>
           
                     
                          </div>
                
                         
                       
                          <p className='text-white cardhead font_16 mb-1 mt-3'>{this?.state?.trending[2]?.name}</p>
                          <p className="desc_grey_txt pb-0 mb-0">1 BNB = {this?.state?.trending[2]?.presaleRate + this?.state?.trending[2]?.symbol}</p>
                         
          
                              
                   
                    
                     
             
             
                  
                      
                          </div>
                      </div>
                  </div>
                  
          
              </div>
                    </div>
                </Container> */}


                

                {/* <Container className="mt-2 container pt-2">
                  <Row className="row_frist_home">
                    <Col
                      className="mb-5 mt-4"
                      xxl={3}
                      xl={3}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <div className="liquid_raised w-100 text-center position-relative">
                        <div className="icon_align">
                          <img src={home_image} />
                        </div>
                        {this.state.loadingText?
                         <div><h4 className="text-white mt-3">
                          <PlaceholdingLoader classnames="stripe small-stripe mb-2" parentclassname="loading" />

                          </h4></div> :<div>
                               <img className="dashimg" src={coin_img} />
                        <h4 className="text-white mt-2">
                        3.1 million $
                          </h4></div>
                        }
                        
                        
                        <span className="font15">Total Liquid Raised</span>
                      </div>
                    </Col>
                    <Col
                      className="mb-5 mt-4"
                      xxl={3}
                      xl={3}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <div className="liquid_raised text-center position-relative">
                        <div className="icon_align">
                          <img src={home_image} />
                        </div>
                        {this.state.loadingText?<>
                         <h4 className="text-white mt-3">
                          <PlaceholdingLoader classnames="stripe small-stripe mb-2" parentclassname="loading" />
                        
                          </h4></> : <>
                          <img className="dashimg" src={coin_img} />
                        <h4 className="text-white mt-2">
                          12
                          </h4></>
  }
                        <span className="font15">Total Projects</span>
                      </div>
                    </Col>
                    <Col
                      className="mb-5 mt-4"
                      xxl={3}
                      xl={3}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <div className="liquid_raised text-center">
                        <div className="icon_align">
                          <img src={home_image} />
                        </div>
                        {this.state.loadingText?<>
                         <h4 className="text-white mt-3">
                          <PlaceholdingLoader classnames="stripe small-stripe mb-2" parentclassname="loading" />
                        
                          </h4></> :<>
                          <img className="dashimg" src={coin_img} />
                        <h4 className="text-white mt-2">5k+
                          </h4></>
  }
                        <span className="font15">Total Participants</span>
                      </div>
                    </Col>
                    <Col
                      className="mb-5 mt-4"
                      xxl={3}
                      xl={3}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <div className="liquid_raised text-center">
                        <div className="icon_align">
                          <img src={home_image} />
                        </div>
                        {this.state.loadingText?<>
                         <h4 className="text-white mt-3">
                          <PlaceholdingLoader classnames="stripe small-stripe mb-2" parentclassname="loading" />
                        
                          </h4></> :<>
                          <img className="dashimg" src={coin_img} />
                        <h4 className="text-white mt-2">1million$ +
                          </h4></>
  }
                        <span className="font15">Total Values Locked</span>
                      </div>
                    </Col>
                  </Row>
                </Container> */}



            
                {/* <Container className="mt-3 text-white container suite_container">
                  <div className="bg_style2">
                  </div>
                  <div className="bg_style">
                  </div>
                  <div className="bg_outline">
               
                  </div>
                  <center>
                    <h3 className="h3_res">A Suite of Tools for Token Sales.</h3>
                  </center>
                  <Row className="d-flex justify-content-center">
                    <Col
                      className="text-center"
                      xxl={8}
                      xl={8}
                      lg={8}
                      md={12}
                      sm={6}
                      xs={12}
                    >
                      <small className="title_span">
                        A suite of tools were built to help you create your own
                        tokens and launchpads in a fast, simple and cheap way
                        with no prior code knowldege required and 100%
                        decentralized!
                      </small>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                        <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={standard_icon}
                          src={favicon}
                        />
                        </div>
                       
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Standard</Card.Title>
                          <Card.Text className="sub_text">
                            Mint standard tokens on BNB.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={deflationary_icon}
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Deflationary</Card.Title>
                          <Card.Text className="sub_text">
                            Generate deflationary tokens with tax and/or charity
                            functions.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={customization_icon}
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Customization</Card.Title>
                          <Card.Text className="sub_text">
                            Create a token sale for your own custom token
                            easily.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={launchpad_icon}
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Launchpad</Card.Title>
                          <Card.Text className="sub_text">
                            Use the token you mint to create a launchpad with
                            just a few clicks
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={branding_icon}
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Branding</Card.Title>
                          <Card.Text className="sub_text">
                            Adding logo, social links, description, listing on
                            FuzzySale
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={management_icon}
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Management</Card.Title>
                          <Card.Text className="sub_text">
                            The portal to help you easily update content for
                            your launchpad.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={community_icon}
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Community</Card.Title>
                          <Card.Text className="sub_text">
                            Promote your launchpad to thousands of buyers on
                            FuzzySale.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          // src={locking_icon}
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>Locking</Card.Title>
                          <Card.Text className="sub_text">
                            Lock your liquidity to SPAISwap, Susiswap after
                            presale.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container> */}

                {/* <Container className="mt-3 text-white container grow_sec">
                <div className="bg_outline2">
                   
                  </div>
                  <center>
                    <h3  className="h3_res">A Growing Protocol Ecosystem.</h3>
                  </center>
                  <Row className="d-flex justify-content-center">
                    <Col
                      className="text-center"
                      xxl={8}
                      xl={8}
                      lg={8}
                      md={12}
                      sm={6}
                      xs={12}
                    >
                      <small className="title_span">
                        We build a suite of tools for the world of decentralized
                        finance. SPAIMoon, SPAISale, SPAIElon of SPAILock,
                        SPAISwap, we SPAI everything!
                      </small>
                    </Col>
                  </Row>

                  <Row className="mt-4 d-flex justify-content-center">
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>SPAI Moon</Card.Title>
                          <Card.Text className="sub_text">
                            The best launchpad for professional teams
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                 
                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>SPAI Swap</Card.Title>
                          <Card.Text className="sub_text">
                            Swap tokens and farming #SPAI.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  

                    <Col
                      className="mb-4"
                      xxl={3}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Card className="card_bg h-100 py-3">
                      <div className="card_img_pare_div">
                        <Card.Img
                          variant="top"
                          className="mx-auto"                          
                          src={favicon}
                        />
                        </div>
                        <Card.Body className="text-center pb-5">
                          <Card.Title>SPAI Elon</Card.Title>
                          <Card.Text className="sub_text">
                            The first meme token on SPAIMoon.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                 
                  </Row>
                  <center>
                    <small className="mt-3 bottom_text">
                      Disclaimer: The information provided shall not in any way
                      constitute a recomendation as to whether you should invest
                      in any product discussed. We accept no liability for any
                      loss occasioned to any person acting or refraining from
                      action as a result of any material provided or published.
                    </small>
                  </center>
                </Container> */}

             


             

                {/* end ongoing_sec */}


                

            </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default Landing;
