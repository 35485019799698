import axios from "axios";
import * as api from "../routes/userroutefront";
import { API_URL } from "../config/env";
import { addReferrer ,GetReferrer} from "../routes/adminroutesfront";


// export const UseAddReferrer = async(data) => {
//   // console.log("login data" , data);
//   var formdata = new FormData();
//   try{
//     if(data){
//       formdata.append("parantaddress", data?.parantaddress);
//       formdata.append("saleaddress", data?.saleaddress);
//       formdata.append("referrerList", data?.referrerList);
//     }
//     try {
//       let respdata = await axios({
//         'method': 'POST',
//         'url': API_URL+api.addReferrer,
//         'credentials': true,
//         'headers': {
//           'content-Type':'multipart/form-data',
//         },
//         data: formdata,
//       });
//       console.log("response",respdata?.data);
//       return {
//         data: respdata.data
//       }
//     }
//     catch (err) {
//       console.log("err",err);
//       return {

//         error: err
//       }
//     }
//   }
//   catch(e){
//     console.log("error" , e);
//   }
// }

export const UseAddReferrer = async(param) => {

    console.log("log>>>>>>>>>>>",param);

    try{
      console.log("try");
      const data = await axios.post(API_URL + addReferrer, param);

      console.log("datadatadata",data);

      if(data){
        return data;
      }
    }
   catch (e) {
        console.log("error", e);
    }
    
};

export const UseUserReferrerData = async(useraddress) => {
  console.log("call");
   
  const tokeninfo =  await   axios.get(`${API_URL + GetReferrer}/${useraddress}`)
        return tokeninfo.data;
}