import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, InputGroup, FormControl, ProgressBar } from 'react-bootstrap';
import { getAccount } from "../hooks/useAccount";
import { addWhitelistMembers } from "../hooks/useAdmin";
import toast, { Toaster } from 'react-hot-toast';
import { IsValidAddress } from '../hooks/useContract';
import { addwhitelistvalidation } from '../hooks/kycvalidation';
import { GetAirdropdetail, UseTokenInfo, ClaimAirdrop, AirdropClaimedUsers, Claimstatus } from '../hooks/useContract';
import * as api from "../routes/userroutefront";
import { API_URL, CHAINS } from '../config/env';
import axios from 'axios';
import isEmpty from 'is-empty';

import { gettrendingdisplayhook, getusecreatedlaunchhook, getuserdatahook } from '../hooks/usebackend';

const formValue = {
    emailId: ''
}

class ClaimlistModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors:{},
            ClaimModal: true,
            formValue: formValue,
            accountInfo: getAccount(),

        };
    }

    componentDidMount() {
      
        this.getuserData();
    }

    async claim() {
        try {
            var email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
            var respdata
            var data = {
                walletaddress: this.state.accountInfo,
                emailId: this.state.formValue.emailId
            }
            if (!isEmpty(this.state.formValue.emailId) && email_regex.test(this.state.formValue.emailId)) {
                respdata = await axios({
                    'method': 'POST',
                    'url': API_URL + api.claimAirdrop,
                    'credentials': true,
                    data: data,
                });
                this.setState({"errors":""})
                this.setState({ClaimModal:false})
            }
            else if(!email_regex.test(this.state.formValue.emailId)){
                let errors = this.state.errors
                errors = {emailId:"Email should be in correct format"}
                this.setState({"errors":errors})
            }
            else {
                let errors = this.state.errors
                errors = {emailId:"Required"}
                this.setState({"errors":errors})
            }
            console.log("response", respdata);
            if (respdata) {
                var address = window.location.pathname.split('/')[2]
                var claimed = await ClaimAirdrop(address);
                window.location.reload();
            }

        }
        catch (err) {
            console.log("err", err)
        }
    }


    handleChange = (e) => {

        let { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
        console.log("formValue", formValue, formData)
    }



    async getuserData() {
        let userdata = await getuserdatahook(this.state.accountInfo && this.state.accountInfo.toLowerCase());
        console.log("userdata", userdata.data?.data);
    }

    render() {


        const { ClaimModal } = this.state


        return (


            <Modal className="wallet-modal" show={ClaimModal} centered size="md">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h3 className="sec-head ">Claim Rewards</h3>
                    <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body">
                    <p class="input_desc_sm">Email Address <span class="text-success">*</span> </p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl name="emailId" placeholder="Enter Email Address" value={this.state.formValue?.emailId} onChange={(e) => { this.handleChange(e) }}
                            />

                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left">{this.state.errors?.emailId}</span>
                    </div>



                    <div className="text-center">
                        <button className="get-started-btn mt-4" onClick={() => { this.claim() }}>Submit</button>

                    </div>

                </Modal.Body>
            </Modal>



        )
    }

}


export default ClaimlistModal