import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, FormControl, InputGroup } from 'react-bootstrap';
import { settingvalidate } from '../../hooks/kycvalidation';
import { addsettinghook } from '../../hooks/usebackend';
import { GetAirdropdetail, UseTokenInfo, ClaimAirdrop, AirdropClaimedUsers, Claimstatus } from '../../hooks/useContract';
import { CHAINS, API_URL } from "../../config/env";
import { getChainId } from '../../hooks/useAccount';
import proof from "../../images/proof.webp"
import * as api from "../../routes/userroutefront";

import axios from 'axios';
import isEmpty from 'is-empty';

class ClaimViewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claimViewModal: true,

            airdropdata: {},
            tokeninfo: {},
            userclaimstatus: null,
            claimedusers: [],
            claimedtabel: [],
            social: [],
            claimedarray: [],
            email: []

        };
    }

    componentDidMount() {
        this.getairdropdetail();
        console.log("tokeninfo", this.state.tokeninfo)

    }

    async getairdropdetail() {
        try {
            // var proxyaddress = window.location.pathname.split('/')[2];
            var result = await GetAirdropdetail(this.props.airdropAddress);
            console.log("result in airdropdetail", result?.social.length, result);
            let tokendata = await UseTokenInfo(result?.token);
            console.log("tokendata1", tokendata);
            this.setState({ tokeninfo: tokendata, "airdropdata": result, social: result?.social });
            console.log("tokeninfo", this.state.tokeninfo)
            console.log("this.state.airdropdata", this.state.airdropdata, this.state.airdropdata.amountPerHead)
            console.log("this.state.social", this.state.social)
            let claimedarray = await AirdropClaimedUsers(this.props.airdropAddress);
            console.log("claimedarray_data", claimedarray)

            let arraydata = []
            let claimusers = []
            claimedarray.map((data, i) => {
                console.log("claimedarray_data", data.toLowerCase())
                claimusers.push((data).toLowerCase())
                let refdata = { tokenaddress: <a href={`${CHAINS[getChainId()].Testnet}${data}`} target='_blank'>{data} </a>, tokenvalue: <p className='mb-0'>{result?.amountPerHead / 10 ** 18} {tokendata?.symbol}</p> };
                arraydata.push(refdata);
            })
            this.setState({ claimedarray: claimusers })
            let status = await Claimstatus(this.props.airdropAddress);
            this.setState({ "userclaimstatus": status, "claimedusers": arraydata });
            console.log("claimedusers", (this.state.airdropdata.amountPerHead / 10 ** 18) ** this.state.claimedusers.length,this.props.airdropAddress)
            this.claimedUsers()
        } catch (err) {
            console.log(err, "getairdropdetail__errr")
        }
    }


    async claimedUsers() {
        try {
            console.log("claimedUsers", this.state.claimedarray)
            var data = {
                walletaddress: this.state.claimedarray,
            }
            var respdata = await axios({
                'method': 'POST',
                'url': API_URL + api.getclaimedUsers,
                'credentials': true,
                data: data,
            });
            console.log(respdata, respdata?.data?.result, "respdata")
            this.setState({ email: respdata.data.result })
        }


        catch (err) {
            console.log(err, "claimedUsers__errr")
        }
    }



    render() {


        const { claimViewModal } = this.state


        return (



            <Modal className="wallet-modal" show={claimViewModal} centered size="md">
                <Modal.Header className='pt-3 header_odal_head'>
                    <h3 className="sec-head ">Claimed Users</h3>
                    <button type="button" class="close" onClick={() => {this.props.onDismiss(); this.setState({email:""})}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet modal_body pt-0 pb-0 px-0">

                    <div className="wallet-lists px-2">

                        <div className='mod_pad_space pt-0'>
                            <div className='row'>
                                <div className='col-12 col-md-12 px-1 mb-3'>



                                </div>


                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <div className='scroll_view_claim'>
                                        
                                        {this.state.email?.length > 0 ? 
                                            <div class="table-responsive">
                                        <table className='table recepients_table recepients_table_claim_v'>
                                            <thead>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>Wallet Address</td>
                                                    </tr>

                                            </thead>
                                            <tbody>
                                                {
                                        this.state.email?.map((val, i) => {
                                         
                                            return (
                                                <tr>
                                                  
                                                          <td> {val.emailId ==="" ? '-' :val.emailId}</td>  
                                                           <td>{val.walletaddress}</td>
                                                           

                                                    
                                                </tr>
                                            )


                                        })}
                                        </tbody></table> 
                                        </div>
                                        : 
                                        <div className='view_mail_stat mb-3'>

                                            <p className='mb-0 text-center'>No claimed users</p>
                                        </div>
                                        }


                                        {/* 
                            <div className='view_mail mb-3'>
                                <p className='mb-0'>abc@gmail.com</p>
                            </div>

                            <div className='view_mail mb-3'>
                                <p className='mb-0'>abc@gmail.com</p>
                            </div>

                            <div className='view_mail mb-3'>
                                <p className='mb-0'>abc@gmail.com</p>
                            </div>

                            <div className='view_mail mb-3'>
                                <p className='mb-0'>abc@gmail.com</p>
                            </div> */}
                                    </div>


                                </div>




                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>



        )
    }

}


export default ClaimViewModal