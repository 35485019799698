import React, { Component } from "react";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Trendingslider from '../trendingslider';
import TokenListModal from "../Launchpad/TokenListModal";
import toast, { Toaster } from 'react-hot-toast';
import { iconTheme, position, style } from '../../hooks/useToast';
import CopyToClipboard from "react-copy-to-clipboard";
import Walletmodal from "../Walletmodal";



import "../../css/styles.css";


import favicon from "../../images/Bitdeal/logo_curve.png";


import {
  getSaleCards,
  getSaleCardsLimit,
  getTotalSalesInfo,
  searchCards,
  searchSale,
} from "../../hooks/useProjects";
import {
  Container,
  Dropdown,
  Row,
  Col,
  Card,
  ProgressBar,
  InputGroup,
  Form,
  Tab,
  Nav,
  FormControl
} from "react-bootstrap";
import { validliquiditytoken, validstandardtoken } from "../../hooks/kycvalidation";
import { CreateLiquidityToken, CreateStandardToken, GetAdminfee, Getbalance, sleep, toFixedNumber } from "../../hooks/useContract";
import { UserTokenList, addTokenCreationhook } from "../../hooks/usebackend";
import { getAccount } from "../../hooks/useAccount";
import { loginApi } from "../../routes/adminroutesfront";


class CreateToken extends Component {




  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.5;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }


  showLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    // document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div_connect").classList.remove("d-none");
    document.getElementById("loader_div_connect").classList.add("d-block");


  }

  hideLoaderConnect() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    // document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div_connect").classList.remove("d-block");
    document.getElementById("loader_div_connect").classList.add("d-none");
  }

  async fetchdata1() {

    if (!await window?.ethereum?._metamask.isUnlocked() || !localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")) {
      this.showLoaderConnect()
    }
    else {
      this.hideLoaderConnect();
      // this.showLoaderConnect()
    }
  }


  onDismiss() {
    this.setState({ walletModal: false });
    if (sessionStorage.getItem("accountInfo")) {
      this.hideLoaderConnect();
    }
  }

  componentDidMount() {
    document.getElementById("launchpad_parent").classList.add("active");
    this.hideLoader();
    this.getfee();
    this.gettokensData();
    this.fetchdata1()

  }
  getfee = async () => {
    let fee = await GetAdminfee();
    console.log("fee", fee);
    this.setState({ poolfee: fee.tokenfee / 10 ** 18 });
    this.setState({ liqfee: fee.liquidityTokenFee / 10 ** 18 });
  }



  gettokensData = async () => {

    var accountInfo = await getAccount();
    var Tokensinfo = await UserTokenList(accountInfo);
    this.setState({ tokenlength: Tokensinfo?.data?.length })




  };



  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      accountInfo: '',

      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      setUpdateCms: false,
      tokenListModal: false,
      tokenlength: 0,
      tokentype: "Standard Token",
      name: "",
      symbol: "",
      decimal: "",
      tokensupply: "",
      errors: {},
      yieldfee: "",
      liquidityfee: "",
      exchangethresold: "",
      maximumtransaction: "",
      userbalance: Getbalance(),
      poolfee: "",
      liqfee: "",
      useraddress: getAccount(),
    };
  }





  handlecreatetoken = async () => {
    if (this.state.tokentype == "Standard Token") {
      let payload = {
        name: this.state.name,
        symbol: this.state.symbol,
        decimal: this.state.decimal,
        totalsupply: this.state.tokensupply
      }
      let validate = await validstandardtoken(payload);
      if (validate?.isValid) {
        let arg = [];
        arg[0] = this.state.name;
        arg[1] = this.state.symbol;
        arg[2] = this.state.decimal;
        arg[3] = (toFixedNumber(this.state.tokensupply * 10 ** this.state.decimal)).toString()
        let tokendata = await CreateStandardToken(arg).then(async (data) => {
          console.log("data>>>>", data);
          await sleep(1000)
          if (data) {
            const tokenList = {
              useraddress: data?.from,
              tokenaddress: data?.events[0].address,
              name: this.state.name,
              symbol: this.state.symbol,
              decimal: this.state.decimal,
              type: "StandardToken"
            }
            await addTokenCreationhook(tokenList)
          }


          console.log("tokendata", data);
          sessionStorage.setItem("HASH", JSON.stringify(data))
          this.props.history.push("/tokensuccess", { state: "token" })
        })
      }
      else {
        this.setState({ errors: validate?.errors })
      }
    }

    if (this.state.tokentype == "Liquidity Generator Token") {
      let payload = {
        name: this.state.name,
        symbol: this.state.symbol,
        decimal: this.state.decimal,
        totalsupply: this.state.tokensupply,
        yieldfee: this.state.yieldfee,
        liquidityfee: this.state.liquidityfee,
        maximumtransaction: this.state.maximumtransaction,
        exchangethresold: this.state.exchangethresold
      }
      console.log("payload", payload);
      let validate = await validliquiditytoken(payload);
      if (validate?.isValid) {
        let arg = [];
        arg[0] = this.state.name;
        arg[1] = this.state.symbol;
        arg[2] = this.state.decimal;
        arg[3] = this.state.tokensupply; //(toFixedNumber(this.state.tokensupply*10**this.state.decimal)).toString();
        arg[4] = this.state.yieldfee;
        arg[5] = this.state.liquidityfee;
        arg[6] = this.state.maximumtransaction;
        arg[7] = this.state.exchangethresold;
        let tokendata = await CreateLiquidityToken(arg).then(async (data) => {
          console.log("data>>>>>>", data);
          await sleep(1000)
          if (data) {
            const tokenList = {
              useraddress: data?.from,
              tokenaddress: data?.events[0].address,
              name: this.state.name,
              symbol: this.state.symbol,
              decimal: this.state.decimal,
              type: "LiquidityToken"
            }
            await addTokenCreationhook(tokenList)
          }



          sessionStorage.setItem("HASH", JSON.stringify(data))
          this.props.history.push("/tokensuccess", { state: "token" })
        })
      }
      else {
        this.setState({ errors: validate?.errors })
      }
    }
  }



  render() {
    const location = this.props.location.pathname.split("/")[1];

    const {
      totalSales,
      onGoingSales,
      upComingSales,
      search,
      isSearch,
      searchSale,
      accountInfo,
      tokenListModal,
      walletModal
    } = this.state;

    return (
      <div id="loader_main">
        <div id="loader_div">
          {tokenListModal && <TokenListModal onDismiss={() => this.setState({ tokenListModal: false })} />}
          <span className="spin_round"></span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className="logo_overlay" id="logo_overlay">
          <Header />

          <div className="whole_sec pb-5">
            <div className="flex_side_right">
              <Sidebar />
              {/* ongoing_sec */}
              <div className="right_side_sec">
                <div id="loader_div_connect">

                  <div className='load_connect_text'>
                    <button className="get-started-btn btn_connect_loader" onClick={() => this.setState({ walletModal: true })}>
                      Connect Wallet to Continue
                    </button>
                  </div>

                </div>

                {/* <div className="text-white topBar d-flex justify-content-between">
                  
                    <small>Trending</small>
                  <small>
                    #1&nbsp;<span>Husy</span>
                  </small>
                  <small>
                    #2&nbsp;<span>ORL</span>
                  </small>
                  <small>
                    #3&nbsp;<span>UWC</span>
                  </small>
                  <small>
                    #4&nbsp;<span>777</span>
                  </small>
                  <small>
                    #5&nbsp;<span>IDXS</span>
                  </small>
                  <small>
                    #6&nbsp;<span>COUGNU</span>
                  </small>
                  <small>
                    #7&nbsp;<span>CRICLE</span>
                  </small>
                  <small>
                    #8&nbsp;<span>KASA</span>
                  </small>
                  <small>
                    #9&nbsp;<span>FIFAPP</span>
                  </small>
                  <small>
                    #10&nbsp;<span>SOG</span>
                  </small>
                  <small>
                    #11&nbsp;<span>COOSHA</span>
                  </small>
                  <small>
                    #12&nbsp;<span>Honey</span>
                  </small>
                  
                  
                </div> */}
                {/* <Trendingslider/> */}
                <div className="right_side_spacing">
                  <Container className="mt-5 pb-3 px-1">
                    <div className='col-12  col-md-10 col-lg-9 mx-auto px-0'>
                      <div className="tab_img">
                        <div className='card_bg card'>
                          <div className='card-body'>
                            <div>
                              {this.state.tokenlength > 0 ?
                                <div className=" text-right ">
                                  <button className="get-started-btn" onClick={() => this.setState({ tokenListModal: true })}>View Token List</button>
                                </div> : <></>}
                              <div className='row'>


                                <div className='col-12 col-md-12 mb-0'>
                                  <p className='input_desc_sm'>Token Type <span className="text-success">*</span> </p>
                                  <div className="inputs input-groups">


                                    <select className="form-control custm_sel" id="exampleFormControlSelect3"
                                      value={this.state.tokentype}
                                      onChange={(e) => { this.setState({ "tokentype": e.target.value }) }} placeholder="Standard Token"
                                    >
                                      <option value="Standard Token">Standard Token</option>
                                      <option value="Liquidity Generator Token">Liquidity Generator Token</option>

                                    </select>
                                    {/* <InputGroup className="">

                          
                            <FormControl value={this.state.tokentype} onChange={(e)=>this.setState({ "tokentype": e.target.value }) } id="softcap" placeholder="Standard Token"

                                aria-describedby="basic-addon2" as = "select"
                            >
                              <option value="Standard Token">Standard Token</option>
                              <option value="Liquidity Generator Token">Liquidity Generator Token</option>
                            </FormControl>
                          
                        </InputGroup> */}
                                  </div>
                                  <div className='note_desc mt-1 mb-0'>
                                    <p>Token Creation Fee {this.state?.tokentype == "Standard Token" ? this.state.poolfee : this.state.liqfee} BNB</p>
                                  </div>
                                </div>

                                <div className='col-12 col-md-12 mb-3'>
                                  <p className='input_desc_sm'>Name <span className="text-success">*</span></p>
                                  <div className="inputs input-groups">
                                    <InputGroup className="">
                                      <FormControl id="hardcap" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }); }} placeholder="Ex:Ethereum"
                                        aria-describedby="basic-addon2"
                                      />

                                    </InputGroup>
                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.name}</span>
                                  </div>
                                </div>

                                <div className='col-12 col-md-12 mb-3'>
                                  <p className='input_desc_sm'>Symbol <span className="text-success">*</span></p>
                                  <div className="inputs input-groups">
                                    <InputGroup className="">
                                      <FormControl id="mincontribution" value={this.state.Symbol} onChange={(e) => this.setState({ symbol: e.target.value })} placeholder="Ex:BNB"
                                        aria-describedby="basic-addon2"

                                      />

                                    </InputGroup>
                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.symbol}</span>
                                  </div>
                                </div>

                                <div className='col-12 col-md-12 mb-3'>
                                  <p className='input_desc_sm'>Decimals <span className="text-success">*</span></p>
                                  <div className="inputs input-groups">
                                    <InputGroup className="">
                                      <FormControl id="decimals" placeholder="Ex: 18"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => this.setState({ decimal: e?.target?.value })}
                                      />

                                    </InputGroup>
                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.decimal}</span>
                                  </div>
                                </div>

                                <div className='col-12 col-md-12 mb-3'>
                                  <p className='input_desc_sm'>Total Supply <span className="text-success">*</span></p>
                                  <div className="inputs input-groups">
                                    <InputGroup className="">
                                      <FormControl id="totalsupply" placeholder="Ex: 100000000000"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => this.setState({ tokensupply: e?.target?.value })}
                                      />

                                    </InputGroup>
                                    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.totalsupply}</span>
                                  </div>
                                </div>

                                {this.state.tokentype == "Liquidity Generator Token" &&
                                  <>
                                    <div className='col-12 col-md-12 mb-3'>
                                      <p className='input_desc_sm'>Transaction fee to generate yield (%) <span className="text-success">*</span></p>
                                      <div className="inputs input-groups">
                                        <InputGroup className="">
                                          <FormControl id="totalsupply" placeholder="Ex: 51"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => this.setState({ yieldfee: e?.target?.value })}
                                          />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.yieldfee}</span>
                                      </div>
                                    </div>

                                    <div className='col-12 col-md-12 mb-3'>
                                      <p className='input_desc_sm'>Transaction fee to generate liquidity (%) <span className="text-success">*</span></p>
                                      <div className="inputs input-groups">
                                        <InputGroup className="">
                                          <FormControl id="totalsupply" placeholder="Ex: 51"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => this.setState({ liquidityfee: e?.target?.value })}
                                          />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.liquidityfee}</span>
                                      </div>
                                    </div>

                                    <div className='col-12 col-md-12 mb-3'>
                                      <p className='input_desc_sm'>Maximum Transaction Amount<span className="text-success">*</span></p>
                                      <div className="inputs input-groups">
                                        <InputGroup className="">
                                          <FormControl id="hardcap" value={this.state.maximumtransaction} onChange={(e) => { this.setState({ maximumtransaction: e.target.value }); }} placeholder="Ex:10"
                                            aria-describedby="basic-addon2"
                                          />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.maximumtransaction}</span>
                                      </div>
                                    </div>

                                    <div className='col-12 col-md-12 mb-3'>
                                      <p className='input_desc_sm'>Exchange Thersold <span className="text-success">*</span></p>
                                      <div className="inputs input-groups">
                                        <InputGroup className="">
                                          <FormControl id="hardcap" value={this.state.exchangethresold} onChange={(e) => { this.setState({ exchangethresold: e.target.value }); }} placeholder="Ex:10"
                                            aria-describedby="basic-addon2"
                                          />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.exchangethresold}</span>
                                      </div>
                                      <div className='note_desc mt-1 mb-0'>
                                        <p>If you keep exchange threshold  is lower than adding liquidity, will be frequent</p>
                                      </div>
                                    </div>
                                  </>
                                }












                                {/* <div className='col-12 col-md-12'>
               <div className="custom-control custom-checkbox">
  <input type="checkbox" className="custom-control-input" id="customCheck1" />
  <label className="custom-control-label" for="customCheck1">Implement Pink Anti - Bot System?</label>
</div></div>     */}

                                <div className='col-12 col-md-12 text-center'>
                                  <button className="get-started-btn mt-2"
                                    disabled={this.state.userbalance < 0.01}
                                    onClick={
                                      // ()=>{this.setState({ currentStep: 1})}
                                      this.handlecreatetoken

                                    }>
                                    Create Token
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                  <Container className='pb-5 px-0 mt-2'>

                    <center>
                      <small className="mt-3 bottom_text">
                        Disclaimer: The information provided shall not in any way
                        constitute a recomendation as to whether you should invest
                        in any product discussed. We accept no liability for any
                        loss occasioned to any person acting or refraining from
                        action as a result of any material provided or published.
                      </small>
                    </center>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
        {walletModal && <Walletmodal connect={"string"} onDismiss={() => this.onDismiss()} />}

      </div>
    );
  }
}

export default CreateToken;
