import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, FormControl, InputGroup } from 'react-bootstrap';
import { editFeevalidation } from '../../hooks/kycvalidation';
import { editFee } from '../../hooks/usebackend';
import toast, { Toaster } from 'react-hot-toast';

import proof from "../../images/proof.webp"

class EditFeemodal extends Component
{
    constructor(props) {
        super(props);
        this.state = {          
            editFeeModal: true,
            feeName: "",
            BNBvalue:0,
            tokenvalue:0,
            errors : {}
           
        };
    }

    componentDidMount(){
        console.log("this.props.",this.props.editFee)
        if(!this.state.BNBvalue && !this.state.tokenvalue){
            this.setState({"BNBvalue" : this.props.editFee?.BNBvalue});
            this.setState({"tokenvalue" : this.props.editFee?.tokenvalue})
        }
    }
    handleedit = async()=>{
        let payload = {
            BNBvalue : this?.state?.BNBvalue,
            tokenvalue : this?.state?.tokenvalue
        }
        let validate = await editFeevalidation(payload);
        if(validate?.isValid){
            let data = {
                id : this.props.editFee?._id,
                BNBvalue : this?.state?.BNBvalue,
                tokenvalue : this?.state?.tokenvalue
            }
            let result = await editFee(data);
            console.log("result",result)
            toast.success(result?.data?.data?.message)
            this.props.onDismiss();
            this.props.getFee()
        }
        else{
            this.setState({"errors" : validate.errors})
        }
    }


   render() {
        
  
        const {editFeeModal} = this.state

        
      return (

        

        <Modal className="wallet-modal" show={editFeeModal} centered size="md">
        <Modal.Header className='pt-3 header_odal_head'>
        <h3 className="sec-head ">Edit Fee Settings</h3>
            <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

        </Modal.Header>
        <Modal.Body className="select-wallet modal_body pt-0 pb-0 px-0">

        <div className="wallet-lists px-2">
                              
        <div className='mod_pad_space pt-0'>
        <div className='row'>
                <div className='col-12 col-md-12 px-1 mb-3'>
                        
                        
                        
                       </div>
                       <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>Fee Name</p>
                                    <div className="inputs input-groups">
                                        <InputGroup className="">
                                            <FormControl id="ipAddress" placeholder="Enter Fee Name"
                                                aria-describedby="basic-addon2"
                                                value={this.props.editFee.feeName}
                                            />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.feeName}</span>
                                    </div>
                                </div>

                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>BNB value</p>
                                    <div className="inputs input-groups">
                                        <InputGroup className="">
                                            <FormControl id="bnb" placeholder="Enter BNB value"
                                                aria-describedby="basic-addon2"
                                                value={this?.state?.BNBvalue}
                                                onChange={(e) => {
                                                    this.setState({ "BNBvalue": e?.target?.value })
                                                }}
                                            />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.BNBvalue}</span>
                                    </div>
                                </div>

                                <div className='col-12 col-md-12 px-1 mb-3'>
                                    <p className='input_desc_sm'>Token value</p>
                                    <div className="inputs input-groups">
                                        <InputGroup className="">
                                            <FormControl id="token" placeholder="Enter token value"
                                                aria-describedby="basic-addon2"
                                                value={this?.state?.tokenvalue}
                                                onChange={(e) => {
                                                    this.setState({ "tokenvalue": e?.target?.value })
                                                }}
                                            />

                                        </InputGroup>
                                        <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tokenvalue}</span>
                                    </div>
                                </div>
                       
          
         
                    <div className='col-12 col-md-12 px-1 text-center'>
                        <button className="get-started-btn mt-2" onClick={this.handleedit}>
                           Save
                        </button>
                        
                        </div>
                </div>

            </div>
    </div>
        </Modal.Body>
    </Modal>



      )
    }

}


export default EditFeemodal