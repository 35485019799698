import React, { Component } from 'react';
import { Modal, InputGroup,FormControl,ProgressBar } from 'react-bootstrap';


class ViewcsvModal extends Component
{
    constructor(props) {
        super(props);
        this.state = {   
                   
            viewcsvModal: true,  
           
           
        };
    }
componentWillReceiveProps(){
    console.log("propsdata" , this.props?.val)
}
// {

   
    render() {
        
  
        const {viewcsvModal} = this.state
        
      return (


        <Modal className="wallet-modal" show={viewcsvModal} centered size="md">
        <Modal.Header className='pt-3 header_odal_head'>
        <h3 className="sec-head ">View CSV file</h3>
            <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

        </Modal.Header>
        <Modal.Body className="select-wallet modal_body">

        <div className="row form_row">
                        <div className="col-12 col-sm-12">
                        <div className="inputs input-groups text_are_grp text_area_norla_colr resize_appra">
                        <InputGroup className="inut_div_vi_sc">
                            <div>
                                <p><span>Address</span> <span>Amount</span></p>
                                {this.props?.val?.map((data) =>
                                <p><span>{data?.address}</span> <span>{data?.amount}</span></p>
                                )}
                                {/* <p><span>0x0000000000000000000000000000000000001000</span> <span>1.049</span></p>
                                <p><span>0x0000000000000000000000000000000000001000</span> <span>1.09</span></p> */}

                            </div>
                        {/* <textarea  
                            id="allocations" rows="10"
                                aria-describedby="basic-addon2"
                               value="address amount 
                               0x0000000000000000000000000000000000001000 13.49 
                               0x0000000000000000000000000000000000001000 1.049 
                               0x0000000000000000000000000000000000001000"
                            /> */}
                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left"></span>
                    </div>

                        {/* <div className="inputs input-groups text_are_grp text_area_norla_colr resize_appra resize_appra_none">
                        <InputGroup className="">
                        <textarea  
                            id="allocations" rows="10"
                                aria-describedby="basic-addon2"
                               value="0x0000000000000000000000000000000000001000 13.49 0x0000000000000000000000000000000000001000 1.049 0x0000000000000000000000000000000000001000"
                            />
                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left"></span>
                    </div> */}
                      
                        </div>
                    


</div>



        </Modal.Body>
    </Modal>



      )
    }

}


export default ViewcsvModal