import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useContext, useEffect, useState } from "react";
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import isEmpty from 'is-empty';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ONEDAYINSECONDS, CHAINS, userFeeWithtoken, userFee } from '../../config/env'
import { UseTokenInfo, IsValidAddress, createPresale, toFixedNumber, getSaleAddress, depositTokens, isFloat, Checkaddress, Approvetoken, GetAdminfee, Approveairdropcontract, Checkaddressiairdrop , Createnewairdrop, GetAirdropfee} from '../../hooks/useContract'
import { getAccount } from '../../hooks/useAccount'
import { CURRENT_CHAIN_ID, CURRENT_RPC_URL } from '../../hooks/useWeb3'

import TokenModal from "../Launchpad/TokenModal"

import { Link } from 'react-router-dom';
import { urlvalidation } from '../../hooks/kycvalidation';
import { getkychook } from '../../hooks/usebackend';
import {getChainId} from '../../hooks/useAccount';

const formvalue = {
    logo: '',
    name: '',
    symbol: '',
    address: '',
    decimal: '',
    presaleRate: 0,
    softCap: 0,
    hardCap: 0,
    min: 0,
    max: 0,
    isVested: false,
    isPancake: false,
    pancakeRate: 0,
    liquidityPercent: 0,
    unlockOn: 0,
    startDate: '',
    endDate: '',
    vestPercent: 0,
    vestInterval: 0,
    isWithoutToken: false,
    description: '',
    website: '',
    twitter: '',
    telegram: '',
    deposit: 0,
    decimals: 0,
    createdSale: '',
    isDeposited: false,
    isWhitelisted: true,
    currentStep: 1,
    tokenModal: false,
    //private sale

    //  title : "",
    currency: "BNB",
    tokenaddress: "",
    tokeninfo: {},
    errors: {},
    fee: 5,
    facebook: "",
    github: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtubevideo: "",
    button1: true,
    button2: true,
    button3: true,
    isToken: false,
    userFee: 0,
    WithTokenaddress: "",
    LaunchpadType: true,
    kyc: "",
    approvebutton: false,
    poolfee: 0,
    title : "",
    valueperperson: "",
    totalvalue : ""
};
class CreatePrivatesaletab extends Component {


    constructor(props) {
        super(props);
        this.state = formvalue
    }

    componentDidUpdate() {
        console.log("update");

    }

    // const saledetail = isEmpty(this.state.error) && this.state.presaleRate && this.state.softCap
    // && this.state.hardCap && this.state.min && this.state.max  && this.state.startDate &&
    // this.state.endDate;

    // const panck = this.state.isPancake ? this.state.unlockOn &&
    // this.state.liquidityPercent && this.state.pancakeRate ? true
    // const vestdetail = this.state.isVested ? this.state.vestInterval && this.state.vestPercent : true


    handledisable = () => {
        // console.log("vest" , isvalid);
        this.setState({ button2: false })
        var isvalid = true;
        var i = 1;
        // let pancake = this.state.isPancake ? 
        if (isEmpty(this.state.error) && this.state.presaleRate && this.state.softCap
            && this.state.hardCap && this.state.min && this.state.max && this.state.startDate &&
            this.state.endDate) {
            isvalid = false
            console.log("vest1", isvalid);
            if (this.state.isPancake) {
                isvalid = true;
                console.log("vest2", isvalid);
                if (this.state.unlockOn &&
                    this.state.liquidityPercent && this.state.pancakeRate) {
                    console.log("vest3", isvalid);
                    isvalid = false;
                    console.log("vest3", isvalid);
                }
            }
            // else if(!this.state.ispancake && this.state.isvested){
            //     isvalid = true;
            // }
            // else{
            //     isvalid = false;
            // }
            if (this.state.isVested) {
                isvalid = true;
                console.log("vest4", isvalid);
                if (this.state.vestInterval && this.state.vestPercent) {
                    isvalid = false
                    console.log("vest5", isvalid);
                }
            }
            // else if(this.state.ispancake && !this.state.isvested){
            //     isvalid = true;
            // }
            // else{
            //     isvalid = false;
            // }
            // else{
            //     isvalid = false;
            // }
            // if(!this.state.isvested || !this.state.isPancake){
            //     isvalid = false;
            // }
        }
        this.setState({ button2: isvalid });
        console.log("isvalid", isvalid);
        return isvalid;
    }

    handledisableurlbutton = () => {
        var isvalid = true;
        if (this.state.logo && this.state.website && this.state.facebook && this.state.twitter &&
            this.state.github && this.state.telegram && this.state.instagram && this.state.discord
            && this.state.reddit && this.state.totalvalue && this.state.valueperperson && this.state.description && this.state?.title) {
            isvalid = false;
        }
        this.setState({ button3: isvalid })
    }

    // componentDidMount(){
    //     const CHAIN_ID = CURRENT_CHAIN_ID();
    //     const CURRENT_RPC = CURRENT_RPC_URL();
    //     console.log("current chain id",CHAIN_ID);
    //     let ROUTER;
    //     CHAINS.map((data)=>{
    //         ROUTER = ((data.CHAIN_ID==CHAIN_ID)&&(data.RPC_URL == CURRENT_RPC))&&data.ROUTER;
    //         console.log((data.CHAIN_ID==CHAIN_ID)&&data.ROUTER,"data in map");
    //     })
    // }

    Createairdrop = async() => {
        const totalvalue = toFixedNumber(this.state.totalvalue*10**this.state.tokeninfo?.decimals).toString();
        const perperson = toFixedNumber(this.state.valueperperson*10**this.state.tokeninfo?.decimals).toString();
        const Details = []
        Details.push(this?.state?.title);
        Details.push(this?.state?.description);
        Details.push(this?.state?.website);
        Details.push(this?.state?.twitter);
        Details.push(this?.state?.telegram);
        Details.push(this?.state?.facebook)
        Details.push(this?.state?.logo);
        Details.push(this?.state?.tokeninfo?.name);
        Details.push(this?.state?.tokeninfo?.symbol);
        Details.push(this?.state?.github);
        Details.push(this?.state?.instagram);
        Details.push(this?.state?.discord);
        Details.push(this?.state?.reddit);
        // Details.push(this?.state?.youtubevideo);
        const result = await Createnewairdrop(perperson , totalvalue ,  this.state.tokenaddress , Details)
        console.log("result" , result);
        // this.props.history.push("/airdropdetails/"+result)
        window.location.href = window.location.origin + "/airdropdetails/"+result
    }

    // Presale Creation
    CreateSale = async () => {
        window.onbeforeunload = () => window.confirm("You have'nt deposited tokens to start presale !")
        window.onpopstate = () => window.confirm("You have'nt deposited tokens to start presale !")
        console.log("call", this?.state?.startDate);

        let Addresses = [];
        let Values = [];
        let Details = [];
        let isSetter = [];

        const account = getAccount()

        // console.log("state" , this.state);
        const startTime = this?.state?.startDate;
        const endTime = this?.state?.endDate;

        // let startDiff = 0;
        // let endDiff = 0;
        // var now = new Date();

        // if(startTime > now.getTime())
        //     startDiff = parseInt((new Date(startTime).getTime() - now.getTime())/86400/1000) ;

        // endDiff =  parseInt(((new Date(endTime).getTime() - now.getTime())/86400/1000)-startDiff) ;

        const CHAIN_ID = CURRENT_CHAIN_ID();
        const CURRENT_RPC = CURRENT_RPC_URL();
        let ROUTER;
        CHAINS.map((data) => {
            if ((((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER) != false)
                ROUTER = ((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER;
            console.log("router inside map :", ROUTER);
        })
        console.log("router address:", ROUTER);
        const startDiff = parseInt(parseInt((new Date(startTime).getTime())) / 1000);
        const endDiff = parseInt(parseInt((new Date(endTime).getTime())) / 1000);

        // _token 0
        //_router 1
        Addresses.push(this?.state?.tokenaddress);
        Addresses.push(ROUTER);
        Addresses.push(account);
        Addresses.push(this.state.WithTokenaddress)
        //_min 0 
        //_max 1
        //_rate 2
        // _soft  3
        // _hard 4
        //_pancakeRate  5
        //_unlockon  6
        // _percent 7
        // _start 8
        //_end 9
        //_vestPercent 10
        //_vestInterval 11
        Values.push(toFixedNumber(this?.state?.min * 10 ** 18).toString());
        Values.push(toFixedNumber(this?.state?.max * 10 ** 18).toString());
        Values.push((this?.state?.presaleRate * 10 ** 6).toString());
        Values.push(toFixedNumber(this?.state?.softCap * 10 ** 18).toString());
        Values.push(toFixedNumber(this?.state?.hardCap * 10 ** 18).toString());
        Values.push((this?.state?.pancakeRate * 10 ** 6).toString());
        Values.push(this?.state?.unlockOn.toString());
        Values.push(this?.state?.liquidityPercent.toString());
        Values.push(startDiff.toString());
        Values.push(endDiff.toString());
        Values.push((this?.state?.vestPercent * 100).toString());
        Values.push((this?.state?.vestInterval * ONEDAYINSECONDS).toString());

        Values.push((this?.state?.userFee)?.toString())

        // isAuto 0
        //_isvested 1
        // isWithoutToken 2
        isSetter.push(this?.state?.isPancake);
        isSetter.push(this?.state?.isVested);
        isSetter.push(this?.state?.isWithoutToken);
        isSetter.push(this?.state?.isWhitelisted);
        isSetter.push(this?.state?.currency == "BNB" ? true : false)
        isSetter.push(this?.state?.isToken)
        isSetter.push(this.state.LaunchpadType)
        // description 0 
        // website,twitter,telegram 1,2,3
        //logo 4
        //name 5
        //symbol 6
        Details.push(this?.state?.description);
        Details.push(this?.state?.website);
        Details.push(this?.state?.twitter);
        Details.push(this?.state?.telegram);
        Details.push(this?.state?.logo);
        Details.push(this?.state?.tokeninfo?.name);
        Details.push(this?.state?.tokeninfo?.symbol);
        Details.push(this?.state?.github);
        Details.push(this?.state?.instagram);
        Details.push(this?.state?.discord);
        Details.push(this?.state?.reddit);
        Details.push(this?.state?.youtubevideo);

        // const data = this.validate()
        // if(data)
        //     return false;


        console.log("DATA : ", Addresses, Values, isSetter, Details)
        const sale = await createPresale(Addresses, Values, isSetter, Details, account)
        this.setState({ createdSale: sale });
        console.log("createdSale : ", sale);
    }
    componentDidMount() {
        this.GetTokenAddress("BNB")
        this.handlegetkyc();
        this.getfee();
    }

    getfee = async () => {
        let fee = await GetAirdropfee();
        console.log("fee" , fee);
        this.setState({ poolfee: fee / 10 ** 18 })
    }

    GetTokenAddress(value) {

        let Withtokenaddress;
        if (value === "DAI") {
            Withtokenaddress = CHAINS[getChainId()].DAI
            this.setState({ WithTokenaddress: CHAINS[getChainId()].DAI });
        } else if (value === "USDT") {
            Withtokenaddress = CHAINS[getChainId()].USDT
            this.setState({ WithTokenaddress: CHAINS[getChainId()].USDT });
        } else if (value === "USDC") {
            Withtokenaddress = CHAINS[getChainId()].USDC
            this.setState({ WithTokenaddress: CHAINS[getChainId()].USDC });
        } else if (value === "BNB") {
            Withtokenaddress = CHAINS[getChainId()].BNB
            this.setState({ WithTokenaddress: CHAINS[getChainId()].BNB });
        }

        // this.setState({ WithTokenaddress: Withtokenaddress});

    }

    async approvetoken() {
        await Approveairdropcontract(this.state.tokenaddress , localStorage.getItem("accountInfo"));
        this.setState({ approvebutton: true })
    }

    handlegetkyc = async () => {
        if (isEmpty(this.state.kyc)) {
            let walletaddress = localStorage.getItem("accountInfo");
            let result = await getkychook(walletaddress && walletaddress?.toLowerCase());
            console.log("result", result?.data?.data);
            if (result?.data?.data?.record?.status == 'Approved' || result?.data?.data?.record?.status == "Pending") {
                this.setState({ kyc: result?.data?.data?.status })
            }
        }
    }


    goToSale = () => {
        window.onbeforeunload = undefined
        window.onpopstate = undefined
        window.location.href = `${window.location.origin}/airdropdetail/${this.state.createdSale}`;
    }

    // async getTokenInfo(tokenaddress){
    //     const isValid = await IsValidAddress(tokenaddress);
    //     if(tokenaddress.length == 42 && !isValid)
    //     {
    //         this.setState({errors : "tokendata.errors"});
    //         this.setState({tokeninfo : {}})
    //     }
    // //         toast.error("This is not a Valid Address !",
    // // {
    // //     style: {
    // //     minWidth: '300px',
    // //     minHeight: '55px'
    // //     }
    // // })

    //     console.log("IS valid ",isValid)
    //     if(tokenaddress.length == 42 && isValid){
    //         const token = await UseTokenInfo(tokenaddress);
    //         this.setState({ name: token.name });
    //         this.setState({ symbol: token.symbol });
    //         this.setState({ decimals: token.decimals });
    //     }
    // }

    calculateDepositTokens() {
        this.forceUpdate()
        let topancakeTokens = 0;
        let pancakeTokens = 0;
        const presalePrice = 1 / parseFloat(this.state.presaleRate);
        if (this.state.isPancake) {
            const pancakePrice = 1 / parseFloat(this.state.pancakeRate);
            topancakeTokens = parseFloat(this.state.hardCap) * parseFloat(this.state.liquidityPercent) / 100;
            pancakeTokens = topancakeTokens / pancakePrice;
            console.log("pancake tokens : ", pancakeTokens)
        }
        const netTokens = parseFloat(this.state.hardCap) / presalePrice;
        console.log("net Token : ", pancakeTokens + netTokens)
        this.setState({ deposit: pancakeTokens + netTokens });

    }

    async DepositTokens() {
        const account = getAccount()
        const tokenAmount = toFixedNumber(this.state.deposit * 10 ** this.state.tokeninfo.decimals).toString()
        const isDeposit = await depositTokens(this.state.tokenaddress, this.state.createdSale, tokenAmount, account)
        this.setState({ isDeposited: isDeposit });
    }

    renderDeposit() {
        return (this.state.deposit && this.state.deposit > 0 ?
            <button onClick={this.DepositTokens.bind(this)} className="get-started-btn">Deposit {this.state.deposit} {this.state.symbol}</button> :
            <button onClick={this.calculateDepositTokens.bind(this)} className="get-started-btn">Calculate</button>)
    }

    validate() {
        console.log("this.state.hardCap", typeof this.state.hardCap, "this.state.softCap", typeof this.state.softCap, (this.state.hardCap) < (this.state.softCap), "10<8", 10 < 8);
        if (parseFloat(this.state.hardCap) < parseFloat(this.state.softCap)) {
            toast.error("Hard Cap must be Higher than Soft Cap !",
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
        }
        if ((parseFloat(this.state.presaleRate) < 0)) {
            toast.error("Price must be greater than 0",
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
        }
        if ((parseFloat(this.state.pancakeRate) < 0)) {
            toast.error("Pancake price must be greater than 0",
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                })
        }

        return (parseFloat(this.state.hardCap) < parseFloat(this.state.softCap)) || (parseFloat(this.state.presaleRate) < 0) || (parseFloat(this.state.pancakeRate) < 0);
    }

    filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    onDismiss() {
        this.setState({ tokenModal: false });
    }

    async settokenaddress(value) {
        this.setState({ tokenaddress: value });
        // this.getTokenInfo(e.target.value);
        const tokendata = await Checkaddressiairdrop(value);
        console.log("tokendata", tokendata);
        if (tokendata.isValid) {
            this.setState({ tokeninfo: tokendata.tokeninfo })
            this.setState({ errors: {} });
            // this.setState({lockbutton : tokendata.allowance})
            this.setState({ approvebutton: tokendata.allowance })
            this.setState({ button1: false })
        }
        else {
            let formData = { ...this.state.errors, ["tokenaddress"]: "Invalid token address !" };
            this.setState({ errors: formData });
            this.setState({ tokeninfo: {} })
        }
    }
    render() {

        const { tokenModal } = this.state



        return (
            <div>

                {/* Wizard */}
                <div className='d-none d-xl-block container px-1'>

                </div>

                {/* end Wizard */}
                {/* ongoing_sec */}
                <div className='ongoing_sec form_sec'>


                    <div className="inner_bg mt-5">
                        <div className='row'>
                            <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                                <div className="tab_img">
                                    <div className='card_bg card'>
                                        <div className='card-body'>
                                            <div className='row align-items-center pb-4'>
                                                <div className='col-12 col-md-6 mb-3 mb-md-0'>
                                                    <p className='bottom_text mb-0'>(*) is required field.</p>

                                                </div>

                                            </div>
                                            <div id="firststep" className={this.state.currentStep == 1 ? "d-block" : "d-none"}>
                                                <div className='row'>
                                                    <div className='col-12 col-md-12'>
                                                        <p className='input_desc_sm'>Token address*</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="">
                                                                <FormControl value={this.state.tokenaddress} onChange={(e) => this.settokenaddress(e.target.value)
                                                                } id="tokenaddress" placeholder=""
                                                                    aria-describedby="basic-addon2"
                                                                />

                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.tokenaddress}</span>
                                                        </div>
                                                        <div className='note_desc mt-1 mb-1'>
                                                            <p>Airdrop creation fee: {this.state.poolfee} BNB</p>
                                                        </div>
                                                    </div>

                                                    <div className={isEmpty(this.state.tokeninfo) ? "d-none" : 'col-12 col-md-12 mb-3'}>
                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Name :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.name}</span>
                                                        </p>


                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Symbol :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.symbol}</span>
                                                        </p>

                                                        <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                            <span className='desc_grey_txt'>Decimals :</span>
                                                            <span className='desc_grey_txt font_12'>{this.state.tokeninfo?.decimals}</span>
                                                        </p>
                                                    </div>





                                                    <div className='col-12 col-md-12 text-center'>
                                                        {/* <button className="get-started-btn" onClick={()=>{this.setState({ currentStep: 2})}}
                            disabled = {this.state.button1}
                        >
                           Next
                        </button> */}

                                                        {this.state.approvebutton &&
                                                            <button className="get-started-btn" onClick={() => { this.setState({ currentStep: 2 }) }}
                                                                disabled={this.state.button1}
                                                            >
                                                                Next
                                                            </button>
                                                        }

                                                        {!this.state.approvebutton &&
                                                            <button className="get-started-btn" onClick={() => { this.approvetoken() }}
                                                                disabled={this.state.button1}
                                                            >
                                                                Approve
                                                            </button>
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                            <div id="secondstep" className={this.state.currentStep == 2 ? "d-block" : "d-none"}>
                                                <div className='row'>

                                                    <div className='col-12 col-md-12 mb-3'>

                                                        <p className='input_desc_sm'>Airdrop Title*</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="">
                                                                <Form.Control value={this.state.title} onChange={(e)=>{
                                                                            this.setState({ title: e.target.value });
                                                                            if(!e.target.value){
                                                                                const formvalue = {...this.state.errors , ["title"] : "Invalid title!"};
                                                                                this.setState({errors : formvalue});
                                                                            }
                                                                            else{
                                                                                const formvalue = {...this.state.errors , ["title"] : ""};
                                                                                this.setState({errors : formvalue});
                                                                            }
                                                                            this.handledisableurlbutton();
                                                                            } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.title}</span>

                                                        </div>

                                                    </div>
                                                    <div className='col-12 col-md-12 mb-3'>

<p className='input_desc_sm'>Total Airdrop Value*</p>
<div className="inputs input-groups">
    <InputGroup className="">
        <Form.Control value={this.state.totalvalue} onChange={(e)=>{
                                this.setState({ totalvalue: e.target.value });
                                var rx = new RegExp(/^\d+$/);
                                if(!rx.test(e.target.value)){
                                    const formvalue = {...this.state.errors , ["totalvalue"] : "Invalid Total airdrop value !"};
                                    this.setState({errors : formvalue});
                                }
                                else{
                                    const formvalue = {...this.state.errors , ["totalvalue"] : ""};
                                    this.setState({errors : formvalue});
                                }
                                this.handledisableurlbutton();
                            } }
            placeholder=""
            aria-label="Total AirDrop Value"
            aria-describedby="basic-addon1"
        />
    </InputGroup>
    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.totalvalue}</span>

</div>

</div>
<div className='col-12 col-md-12 mb-3'>

<p className='input_desc_sm'>Airdrop Value per Person*</p>
<div className="inputs input-groups">
    <InputGroup className="">
        <Form.Control value={this.state.valueperperson} onChange={(e)=>{
                                this.setState({ valueperperson: e.target.value });
                                var rx = new RegExp(/^\d+$/);
                                if(!rx.test(e.target.value)){
                                    const formvalue = {...this.state.errors , ["valueperperson"] : "Invalid Total airdrop value !"};
                                    this.setState({errors : formvalue});
                                }
                                else{
                                    const formvalue = {...this.state.errors , ["valueperperson"] : ""};
                                    this.setState({errors : formvalue});
                                }
                                this.handledisableurlbutton();
                            } }
            placeholder=""
            aria-label="Airdrop Value"
            aria-describedby="basic-addon1"
        />
    </InputGroup>
    <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.valueperperson}</span>

</div>

</div>
                                                    <div className='col-12 col-md-12 mb-3'>

                                                        <p className='input_desc_sm'>Logo URL*</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class='fas fa-link'></i></InputGroup.Text>
                                                                <Form.Control value={this.state.logo} onChange={(e)=>{
                                                                        this.setState({ logo: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["logo"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["logo"] : "Invalid Logo Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.logo}</span>

                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-12 mb-3'>

                                                        <p className='input_desc_sm'>Website*</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fa fa-globe"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.website} onChange={(e)=>{
                                                                        this.setState({ website: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["website"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["website"] : "Invalid website Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.website}</span>

                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 mb-3'>

                                                        <p className='input_desc_sm'>Facebook*</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fa fa-facebook"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.facebook} onChange={(e)=>{
                                                                        this.setState({ facebook: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["facebook"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["facebook"] : "Invalid facebook Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.facebook}</span>

                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 mb-3'>

                                                        <p className='input_desc_sm'>Twitter</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fa fa-twitter"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.twitter} onChange={(e)=>{
                                                                        this.setState({ twitter: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["twitter"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["twitter"] : "Invalid twitter Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.twitter}</span>

                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 mb-3'>

                                                        <p className='input_desc_sm'>Github</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fa fa-github"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.github} onChange={(e)=>{
                                                                        this.setState({ github: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["github"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["github"] : "Invalid github Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.github}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 mb-3'>

                                                        <p className='input_desc_sm'>Telegram</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fa fa-telegram"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.telegram} onChange={(e)=>{
                                                                        this.setState({ telegram: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["telegram"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["telegram"] : "Invalid telegram Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.telegram}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 mb-3'>

                                                        <p className='input_desc_sm'>Instagram</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fa fa-instagram"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.instagram} onChange={(e)=>{
                                                                        this.setState({ instagram: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["instagram"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["instagram"] : "Invalid instagram Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.instagram}</span>

                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 mb-3'>

                                                        <p className='input_desc_sm'>Discord</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fab fa-discord"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.discord} onChange={(e)=>{
                                                                        this.setState({ discord: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["discord"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["discord"] : "Invalid discord Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.discord}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-12 mb-3'>

                                                        <p className='input_desc_sm'>Reddit</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                                <InputGroup.Text id="basic-addon1"><i class="fab fa-reddit"></i></InputGroup.Text>
                                                                <Form.Control value={this.state.reddit} onChange={(e)=>{
                                                                        this.setState({ reddit: e.target.value });
                                                                        if(urlvalidation(e.target.value)){
                                                                            const formvalue = {...this.state.errors , ["reddit"] : ""};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        else{
                                                                            const formvalue = {...this.state.errors , ["reddit"] : "Invalid reddit Url!"};
                                                                            this.setState({errors : formvalue});
                                                                        }
                                                                        this.handledisableurlbutton();
                                                                        } }
                                                                    placeholder=""
                                                                    aria-label="Airdrop Title"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.reddit}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-12 mb-3'>

                                                        <p className='input_desc_sm'>Description</p>
                                                        <div className="inputs input-groups">
                                                            <InputGroup className="grp_input_style">
                                                          
                                                                <Form.Control as="textarea" aria-label="With textarea" value={this.state.description} onChange={(e)=>{
                                                                            this.setState({ description: e.target.value });
                                                                            if(!e.target.value){
                                                                                const formvalue = {...this.state.errors , ["description"] : "Invalid Description!"};
                                                                                this.setState({errors : formvalue});
                                                                            }
                                                                            else{
                                                                                const formvalue = {...this.state.errors , ["description"] : ""};
                                                                                this.setState({errors : formvalue});
                                                                            }
                                                                            this.handledisableurlbutton();
                                                                            } }/>
                                                            </InputGroup>
                                                            <span className="text-danger f-12 d-block text-left">{this?.state?.errors?.description}</span>
                                                        </div>
                                                    </div>
                                                  







                                                    <div className='col-12 col-md-12 text-center'>
                                                        <button className="get-started-btn mr-2" onClick={() => { this.setState({ currentStep: 1 }) }}>
                                                            Back
                                                        </button>
                                                        <button className="get-started-btn" onClick={() => {
                                                            console.log("disable", this.handledisableurlbutton());
                                                            // if (this.handledisableurlbutton()) {

                                                            // }
                                                            // else {
                                                            //     this.setState({ currentStep: 3 })
                                                            // }
                                                            // else{
                                                            //     toast.error("")
                                                            // }
                                                            // this.handledisableurlbutton()
                                                            this.Createairdrop()
                                                        }}
                                                        disabled={this.state.button3}
                                                        >

                                                            Create New Airdrop
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>




                {tokenModal && <TokenModal connect={"string"} address={(val) => this.settokenaddress(val)} onDismiss={() => this.onDismiss()} />}

            </div>





        )
    }

}


export default CreatePrivatesaletab