import * as XLSX from 'xlsx';
import Papa from "papaparse";
import { toast } from 'react-hot-toast';


export const   filereader = async(e) => {
    try{
        e.preventDefault();
        var files = e.target.files, f = files[0];
        console.log("readed data" , files);
        var reader = new FileReader();
        reader.onload =   async function (e) {
            var data = e.target.result;
            // var detail = processCSV(data);
            console.log("readed data" , data);
            let readedData = XLSX.read(data, {type: 'binary'});
            console.log("readed data" , readedData);
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws);
            console.log("readed data" , dataParse);
            let user = [];
            let amount = [];
             await dataParse.map((m , i) => {
                user.push(m.address);
                amount.push(m.amount);
            })
            // setFileUploaded(dataParse);
            return{
                user : user,
                amount : amount,
                ref : data
            }
        }
        reader.readAsBinaryString(f)
        // promiseA.then(()=>console.log("async"))
    }
    catch(e){
        console.log("error on file reader" , e);
    }
}


// export const processCSV = (str, delim=',') => {
//     const headers = str.slice(0,str.indexOf('\n')).split(delim);
//     const rows = str.slice(str.indexOf('\n')+1).split('\n');

//     const newArray = rows.map( row => {console.log("row" , row);
//         const values = row.split(delim);
//         const eachObject = headers.reduce((obj, header, i) => {
//             obj[header] = values[i];
//             return obj;
//         }, {})
//         return eachObject;
//     })

//     return (newArray)
// }

// export const filereader = async (event) => {
//     let splitFile = event.target.files[0].name.split(".");
//     console.log("splitFile", splitFile)
//     if (splitFile[splitFile.length - 1] != "xlsx") {
//         toast.error('Select your csv file !');
//         return false
//     }
//     const valuesArray = [];
//     // this.setState({ 'filename': event.target.files[0].name })
//     Papa.parse(event.target.files[0], {
//         header: true,
//         skipEmptyLines: true,
//         complete: async (results) => {
//             console.log("results" , results);
//             // this.setState({ valuesArray: results.data })

//             // Iterating data to get column name and their values
//             // results.data.map((d) => {
//             //     console.log("===============", Object.keys(d));
//             //     valuesArray.push(Object.values(d));
//             // });

//             // Filtered Values
//             // this.setState({ values: valuesArray })
//         },
//     });
// }


export const processCSV = (str, delim=',') => {
    try{
        const headers = str.slice(0,str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n')+1).split('\n');

    console.log("rows" , rows);
    const newArray = rows.map( val => {
        let row = JSON.parse(val)
        const values = row.split(delim);
        console.log("values" , values);
        const eachObject = headers.reduce((obj, header, i) => {
            obj[header] = values[i];
            return obj;
        }, {})
        return eachObject;
    })

    return (newArray)
    }
    catch(e){
        console.log("error on " , e);
    }
}