import React, { Component } from 'react';
import { Modal, InputGroup,FormControl,ProgressBar } from 'react-bootstrap';


class CsvModal extends Component
{
    constructor(props) {
        super(props);
        this.state = {   
                   
            csvModal: true,  
           
           
        };
    }

 CloseModal = ()=>{
    if(this.props.modalHide){
        this.props.modalHide()
        this.props.onDismiss()
    }
    else{
        this.props.onDismiss()
    }
}

   
    render() {
        
  
        const {csvModal} = this.state

        
      return (


        <Modal className="wallet-modal" show={csvModal} centered size="md">
        <Modal.Header className='pt-3 header_odal_head'>
        <h3 className="sec-head ">Sample CSV file</h3>
            <button type="button" class="close" onClick={() => this.CloseModal()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

        </Modal.Header>
        <Modal.Body className="select-wallet modal_body">

        <div className="row form_row">
                        <div className="col-12 col-sm-12">

                        <div className="inputs input-groups text_are_grp text_area_norla_colr resize_appra">
                        <InputGroup className="inut_div_vi_sc">
                            <div>
                                <p>address amount</p>
                                <p>0x0000000000000000000000000000000000001000 13.49</p>
                                <p>0x0000000000000000000000000000000000001000 1.049 </p>
                                <p>0x0000000000000000000000000000000000001000 1.09</p>

                            </div>
                        {/* <textarea  
                            id="allocations" rows="10"
                                aria-describedby="basic-addon2"
                               value="address amount 
                               0x0000000000000000000000000000000000001000 13.49 
                               0x0000000000000000000000000000000000001000 1.049 
                               0x0000000000000000000000000000000000001000"
                            /> */}
                        </InputGroup>
                        <span className="text-danger f-12 d-block text-left"></span>
                    </div>
                      
                        </div>
                    


</div>



        </Modal.Body>
    </Modal>



      )
    }

}


export default CsvModal