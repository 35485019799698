import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import airimg from "../../images/Bitdeal/logos.png";
import { AirdropClaimedUsers, GetAirdropdetail, UseTokenInfo } from '../../hooks/useContract';

class CreatePrivatesale extends Component {


  constructor(props) {
    super(props);
    this.state = {
      tokeninfo: {},
      claimed: "",
      airinfo: {},
      name: "",
      logo: ""
    }
  }


  componentDidMount() {
    this.handlegettokendata();
  }

  async handlegettokendata() {
    console.log("tokendata", this.props.carddata);
    let tokendata = await UseTokenInfo(this.props.carddata?.token);
    var result = await GetAirdropdetail(this.props.carddata?.airdropaddress);
    console.log("result", result);
    this.setState({ airinfo: result, name: result?.social[0], logo: result?.social[6] })
    let claimedarray = await AirdropClaimedUsers(this.props.carddata?.airdropaddress);
    console.log("tokendata1", tokendata, claimedarray);
    this.setState({ tokeninfo: tokendata, claimed: claimedarray?.length });
  }


  render() {


    return (
      <>
        <div>
          <div className='card_bg  card'>
            <div className='card-body'>
              <div className='d-flex justify-content-between align-items-center'>
                <img className='airpodsimg' src={this.state.logo} />
                <a class="get-started-btn mr-2 link_wallet_new live-btn"><span class="pl-2">Live</span></a>
                {/* <a class="get-started-btn mr-0 link_wallet_new upcoming-btn"><span class="pl-2">Upcoming</span></a>
                                                <a class="get-started-btn mr-0 link_wallet_new end-btn"><span class="pl-2">End</span></a> */}
              </div>
              <h4 className='airh4 mt-3'>{this.state?.name}</h4>
              <ul>
                <li className='d-flex justify-content-between'>
                  <p>Token :</p>  <p>{this?.state?.tokeninfo?.name}</p>
                </li>
                <li className='d-flex justify-content-between'>
                  <p>Total Token :</p>  <p>{isNaN(this?.props?.carddata?.totalInvested / 10 ** this?.state?.tokeninfo?.decimals) ? 0 : (this?.props?.carddata?.totalInvested / 10 ** this?.state?.tokeninfo?.decimals).toFixed(0)}</p>
                </li>
                <li className='d-flex justify-content-between'>
                  <p>Participants :</p>  <p>{this?.state?.claimed}</p>
                </li>
              </ul>
              <div className='d-flex justify-content-center align-items-center'>
                {/* <p className='mb-0 startsin'>Starts In</p> */}
                <a class="get-started-btn mr-0 link_wallet_new" href={'/airdropdetails/' + this?.props?.carddata?.airdropaddress}><span class="pl-2">View Airdrop</span></a>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default CreatePrivatesale

