import isEmpty from 'is-empty';




export const ObjectIsempty =(Obj)=>{
    try{
        let objectArr = Object.values(Obj)
        console.log("objectArr",objectArr)
        for(let i=0;i<objectArr.length;i++){
            if(!isEmpty(objectArr[i])){
                console.log("if")
                return false
            }else{
                console.log("else")
                return true
                
            }
        }
        console.log("outofthefun")
        return true
    }catch(err){
        console.log(err,'ObjectIsempty_err')
    }
}